import types from "../../actions/clientsActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const newContact = (
  state = {
    data: {},
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { error, data } = {} } = action;
  switch (action.type) {
    case types.SAVE_NEW_CONTACT_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.SAVE_NEW_CONTACT_SUCCESS:
      return {
        status: FETCH_STATUS.LOADED,
        data: data,
      };
    case types.SAVE_NEW_CONTACT_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
  
    default:
      return state;
  }
};

export default newContact;

import { createActionTypes } from "../../../utils";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...createActionTypes("CLIENTS", "EDIT_CLIENT_PREFERENCES"),
  ...createActionTypes("CLIENTS", "FETCH_CONTACT_LIST"),
  ...createActionTypes("CLIENTS", "SAVE_NEW_CONTACT"),
  ...createActionTypes("CLIENTS", "DELETE_CONTACT"),
  ...createActionTypes("CLIENTS", "SAVE_NEW_CLIENT"),
  ...createActionTypes("CLIENTS", "FETCH_CLIENT_LIST"),
  ...createActionTypes("CLIENTS", "FETCH_CLIENT_BY_ID"),
  ...createActionTypes("CLIENTS", "EDIT_CONTACT"),
  ...createActionTypes("CLIENTS", "FETCH_ASSOCIATE_LIST"),
  ...createActionTypes("CLIENTS", "FETCH_CLIENT_PREFERENCES"),
  ...createActionTypes("CLIENTS", "FETCH_CLIENT_BY_SEARCH"),
  ...createActionTypes("CLIENTS", "FETCH_CLIENT_INVOICES"),
  ...createActionTypes("CLIENTS", "FETCH_CLIENT_INVOICE_ID"),

};

export const CLEAR_CLIENT_BY_SEARCH =  "CLEAR_CLIENT_BY_SEARCH";
export const CLEAR_INVOICE_DOC =  "CLEAR_INVOICE_DOC"

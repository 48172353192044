import { withFormik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { REGEX } from "../../constants";
import { requestNewPassword } from "../../store/actions/userActions";
import ForgotPasswordForm from "./forgotPassword";

const validationSchema = yup.object().shape({
  person_email: yup
    .string()
    .trim()
    .matches(REGEX.EMAIL_REGEX_SCHEMA, "Tipo de dato incorrecto")
    .max(75, "Máximo 75 caracteres de largo.")
    .required(" "),
});

const ForgotPassword = withFormik({
  validateOnChange: true,
  enableReinitialize: false,
  validationSchema,
  mapPropsToValues: ({ person_email, newPasswordStatus }) => ({
    person_email: person_email || "",
    newPasswordStatus,
  }),
  handleSubmit: (value, { props, setErrors, setFieldValue }) => {
    props.requestNewPassword(value.person_email).then((res) => {
      const { payload } = res;
      if (payload.status === 200) {
        setFieldValue("status", true);
      }
    });
  },
})(ForgotPasswordForm);

const mapDispatchToProps = {
  requestNewPassword,
};

export default connect(null, mapDispatchToProps)(ForgotPassword);

import axios from "axios";
import moment from "moment";
import { USER_PROFILE_ENDPOINT } from "../constants";

const getProfile = async () => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "GET",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  let response = await axios.get(USER_PROFILE_ENDPOINT, requestOptions);
  if (response.status === 200) {
    let startTime = moment();
    response.data.startTime = startTime;
    response.data.role = "auth.change_group"; //Just for testing porpuses
    sessionStorage.setItem("profile_data", JSON.stringify(response.data));

    return response;
  } else throw Error("Error getting profile");
};

const saveProfileEdition = async (data) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "PUT",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };

  let response = await axios.put(USER_PROFILE_ENDPOINT, data, requestOptions);

  return response;
};

export const profileService = {
  getProfile,
  saveProfileEdition,
};

import styled from "styled-components";

const StyledLi = styled.li`
  color: ${(props) => props.active ? "var(--mainColor)":null};
  background-color: ${(props) => props.active ? (props.isAdmin ? "#d1cdcd":"#565656") : null};
  span{
    color: ${(props) => props.active ? (props.isAdmin ? "var(--mainColor)!important" : "#565656") : null};
  }
`;

export { StyledLi };

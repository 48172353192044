import types from "../../actions/digitalCertificateActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const revokeDigitalCertificate = (
    state = {
        data: [],
        status: FETCH_STATUS.NOT_LOADED,
        error: "",
    },
    action
) => {
    const { payload: { data, error } = {} } = action;
    switch (action.type) {
        case types.REVOKE_CERTIFICATE_REQUEST:
            return {
                ...state,
                status: FETCH_STATUS.LOADING,
                error: null,
            };
        case types.REVOKE_CERTIFICATE_SUCCESS:
            return {
                ...state,
                data: data,
                status: FETCH_STATUS.LOADED,
            };
        case types.REVOKE_CERTIFICATE_FAILURE:
            return {
                ...state,
                status: FETCH_STATUS.FAILED,
                error,
            };
        default:
            return state;
    }
};

export default revokeDigitalCertificate;
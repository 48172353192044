import { createActionTypes } from "../../../utils";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ...createActionTypes("AGREEMENTS", "FETCH_AGREEMENTS_LIST"),
    ...createActionTypes("AGREEMENTS", "SAVE_NEW_AGREEMENT"),
    ...createActionTypes("AGREEMENTS", "FETCH_AGREEMENT_DOC"),
    ...createActionTypes("AGREEMENTS", "DELETE_AGREEMENT"),
    ...createActionTypes("AGREEMENTS", "UPDATE_AGREEMENT"),
};

export const CLEAR_AGREEMENT_DOC = "CLEAR_AGREEMENT_DOC"
import axios from "axios";
import { REQUESTS_ENDPOINT, BASE_URL } from "../constants";

const saveNaturalPersonOrder = async (newRequest) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "POST",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  const response = await axios.post(
    REQUESTS_ENDPOINT,
    newRequest,
    requestOptions
  );
  return response;
};

const saveLegalPersonRequest = async (newRequest) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "POST",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  const response = axios.post(REQUESTS_ENDPOINT, newRequest, requestOptions);
  return response;
};

const fetchRequestList = async (status, page = 1) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );

  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "GET",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  return await axios.get(
    `${REQUESTS_ENDPOINT}?page=${page}&status__array=${status}`,
    requestOptions
  );
};

const fetchRequesById = async (requestId) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "GET",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };

  return await axios.get(`${REQUESTS_ENDPOINT}${requestId}/`, requestOptions);
};

const fetchDocumentRequestById = async (requestId, fileName) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "GET",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };

  return await axios.get(
    `${REQUESTS_ENDPOINT}${requestId}/files/${fileName}/`,
    requestOptions
  );
};

const fetchCertificateRequestById = async (requestId, requestType) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "GET",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };

  return await axios.get(
    `${REQUESTS_ENDPOINT}${requestId}/certificates/${requestType}/`,
    requestOptions
  );
};

const fetchRequestBySearch = async (query, page = 1) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "GET",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };

  return await axios.get(
    `${REQUESTS_ENDPOINT}?page=${page}&search=${query}&generic_search_fields=created&generic_search_fields=serial&generic_search_fields=request_type&generic_search_fields=status&generic_search_fields=person_first_name&generic_search_fields=person_last_name_1&generic_search_fields=person_last_name_2&generic_search_fields=representative_first_name&generic_search_fields=representative_last_name_1&generic_search_fields=representative_last_name_2&generic_search_fields=company_cif&generic_search_fields=person_national_id&generic_search_fields=representative_national_id`,
    requestOptions
  );
};

const saveRequestStatus = async (id) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "POST",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };

  const res = await axios.delete(
    `${BASE_URL}/requests/${id}/`,
    requestOptions
  );

  return res;
};

const updateNaturalPersonOrder = async (data, id) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "PATCH",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  return await axios.patch(`${REQUESTS_ENDPOINT}${id}/`, data, requestOptions);
};

const updateLegalPersonRequest = async (data, id) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "PATCH",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  return await axios.patch(`${REQUESTS_ENDPOINT}${id}/`, data, requestOptions);
};

const sendRequestUserNotification = (id) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "POST",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  return axios.post(
    `${REQUESTS_ENDPOINT}${id}/resend_email/`,
    {},
    requestOptions
  );
};

export const requestService = {
  saveNaturalPersonOrder,
  saveLegalPersonRequest,
  fetchRequestList,
  fetchRequesById,
  fetchDocumentRequestById,
  fetchCertificateRequestById,
  fetchRequestBySearch,
  saveRequestStatus,
  updateNaturalPersonOrder,
  updateLegalPersonRequest,
  sendRequestUserNotification
};

import { withFormik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { setNewPassword } from "../../store/actions/userActions";
import { REGEX } from "../../constants/index";
import NewPasswordForm from "./newPasswordForm";

const validationSchema = yup.object().shape({
  new_password1: yup
    .string()
    .min(9, "Mínimo nueve caracteres")
    .matches(
      REGEX.ALPHANUMERIC_WHIT_ONE_LETTER_AT_LEAST,
      "Debe contener letras y/o números"
    )
    .required(" "),
  new_password2: yup
    .string()
    .min(9, "Mínimo nueve caracteres")
    .required(" ")
    .oneOf([yup.ref("new_password1"), null], "Las constraseñas no coinciden"),
});

const NewPassword = withFormik({
  validateOnChange: true,
  enableReinitialize: false,
  validationSchema,
  mapPropsToValues: ({ new_password1, new_password2, status }) => ({
    new_password1: new_password1 || "",
    new_password2: new_password2 || "",
    status,
  }),

  handleSubmit: (values, { props, setErrors, setFieldValue }) => {
    let { token, uid } = props.match.params;

    props
      .setNewPassword({
        ...values,
        token,
        uid,
      })
      .then((res) => {
        let { payload } = res;
        if(payload.status === 200) { 
          setFieldValue('status', payload.data.detail)
        } else {
          setFieldValue('status', payload)
        }
      });
  },
})(NewPasswordForm);

export const mapStateToProps = (state) => {

};

const mapDispatchToProps = {
  setNewPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);

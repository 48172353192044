import React from "react";
import ErrorMessage  from "../../components/ErrorMessage";
import Modal from "../../components/Modal";
import { StyledInput } from "./styledComponents";
import { useModal } from "../../hooks";
import logo from '../../Pinpass_logo_provisional.webp';
const NewPassword = ({
  values,
  handleChange,
  handleSubmit,
  errors,
  match,
  handleBlur,
  touched,
  history,
}) => {
  const path = "/login";
  let {showModal, hideModal, onConfirmRedirect} = useModal(
    false,
    values.status,
    path,
    history
  );


  return (
    <>
      <Modal
        show={showModal}
        hideModal={hideModal}
        onConfirm={onConfirmRedirect}
        message={values.status}
        hideButton
      />
      <div className="container forgot">
        <div className="row justify-content-center align-items-center">
          <div className="loginContainer col-md-5">
            <img src={logo} alt="logo"/>
            <div className="form-group col-md-10 offset-md-1">
              <form onSubmit={handleSubmit}>
                <div className="emailfield">
                  <ErrorMessage
                    errorMessage={errors.new_password1}
                  ></ErrorMessage>
                  <br />
                  <div className="form-group has-search">
                    <span className="icon-Black1 iconLogin">&#xe90a;</span>
                    <StyledInput
                      type="password"
                      className="form-control"
                      placeholder="Nueva contraseña"
                      name="new_password1"
                      onChange={handleChange}
                      value={values.new_password1}
                      onBlur={handleBlur}
                      error={touched.new_password1 && errors.new_password1}
                    />
                  </div>
                  <ErrorMessage
                    errorMessage={errors.new_password2}
                  ></ErrorMessage>
                  <div className="form-group has-search">
                    <span className="icon-Black1 iconLogin">&#xe90a;</span>
                    <StyledInput
                      type="password"
                      className="form-control"
                      placeholder="Confirmar nueva contraseña"
                      name="new_password2"
                      onChange={handleChange}
                      value={values.new_password2}
                      onBlur={handleBlur}
                      error={touched.new_password2 && errors.new_password2}
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary col-md-12">
                  Reestablecer contraseña
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="disclaimer col-md-6">
          © Derechos reservados Pinpass 2023
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;

import types, {
  CLEAR_DOCUMENT_REQUEST_BY_ID,
  CLEAR_CERTIFICATE_REQUEST_BY_ID,
  SET_REQUEST_DETAIL_URL_FROM_EMAIL,
  CLEAR_REQUEST_BY_SEARCH,
  SET_REQUEST,
  SET_REQUEST_CERTIFICATE
} from "./types";
import { requestService } from "../../../services/requestService";

/*SAVE REQUEST FOR NATURAL PERSON*/

const saveNaturalPersonOrderRequest = () => {
  return {
    type: types.SAVE_NATURAL_PERSON_ORDER_REQUEST,
  };
};
const saveNaturalPersonOrderSuccess = (data) => {
  return {
    type: types.SAVE_NATURAL_PERSON_ORDER_SUCCESS,
    payload: data,
  };
};
const saveNaturalPersonOrderFailure = (error) => {
  return {
    type: types.SAVE_NATURAL_PERSON_ORDER_FAILURE,
    payload: error,
  };
};
export const saveNaturalPersonOrder = (newRequest) => (dispatch) => {
  dispatch(saveNaturalPersonOrderRequest());
  return requestService
    .saveNaturalPersonOrder(newRequest)
    .then((response) => dispatch(saveNaturalPersonOrderSuccess(response)))
    .catch((error) => dispatch(saveNaturalPersonOrderFailure(error)));
};

export const updateNaturalPersonOrder = (data, id, lastFile = false) => (
  dispatch
) => {
  dispatch(saveNaturalPersonOrderRequest());
  if (lastFile) {
    return requestService
      .updateNaturalPersonOrder(data, id)
      .catch((error) => dispatch(saveNaturalPersonOrderFailure(error)));
  } else {
    return requestService
      .updateNaturalPersonOrder(data, id)
      .then((response) => dispatch(saveNaturalPersonOrderSuccess(response)))
      .catch((error) => dispatch(saveNaturalPersonOrderFailure(error)));
  }
};

/*SAVE REQUEST FOR LEGAL PERSON*/
const saveLegalPersonOrderRequest = () => {
  return {
    type: types.SAVE_LEGAL_PERSON_ORDER_REQUEST,
  };
};
const saveLegalPersonOrderSuccess = (data) => {
  return {
    type: types.SAVE_LEGAL_PERSON_ORDER_SUCCESS,
    payload: data,
  };
};
const saveLegalPersonOrderFailure = (error) => {
  return {
    type: types.SAVE_LEGAL_PERSON_ORDER_FAILURE,
    payload: error,
  };
};

export const saveLegalPersonOrder = (newRequest, hasRemaining = false) => (
  dispatch
) => {
  dispatch(saveLegalPersonOrderRequest());
  if (hasRemaining) {
    return requestService
      .saveLegalPersonRequest(newRequest)
      .catch((error) => dispatch(saveLegalPersonOrderFailure(error)));
  } else {
    return requestService
      .saveLegalPersonRequest(newRequest)
      .then((response) => dispatch(saveLegalPersonOrderSuccess(response)))
      .catch((error) => dispatch(saveLegalPersonOrderFailure(error)));
  }
};

export const updateLegalPersonRequest = (data, id, lastFile) => (dispatch) => {
  dispatch(saveLegalPersonOrderRequest());
  if (lastFile) {
    return requestService
      .updateLegalPersonRequest(data, id)
      .catch((error) => dispatch(saveLegalPersonOrderFailure(error)));
  } else {
    return requestService
      .updateNaturalPersonOrder(data, id)
      .then((response) => dispatch(saveLegalPersonOrderSuccess(response)))
      .catch((error) => dispatch(saveLegalPersonOrderFailure(error)));
  }
};

//END SAVE REQUEST FOR LEGAL PERSON //

const fetchRequestListRequest = () => {
  return {
    type: types.FETCH_REQUEST_LIST_REQUEST,
  };
};
const fetchRequestListSuccess = (data) => {
  return {
    type: types.FETCH_REQUEST_LIST_SUCCESS,
    payload: data,
  };
};
export const fetchRequestListFailure = (error) => {
  return {
    type: types.FETCH_REQUEST_LIST_FAILURE,
    payload: error,
  };
};
export const fetchRequestList = (status, page) => (dispatch) => {
  dispatch(fetchRequestListRequest());
  requestService
    .fetchRequestList(status, page)
    .then((data) => {
      dispatch(fetchRequestListSuccess(data));
    })
    .catch((error) => dispatch(fetchRequestListFailure(error)));
};

const fetchRequestByIdRequest = () => {
  return {
    type: types.FETCH_REQUEST_BY_ID_REQUEST,
  };
};

const fetchRequestByIdSuccess = (data) => {
  return {
    type: types.FETCH_REQUEST_BY_ID_SUCCESS,
    payload: data,
  };
};

const fetchRequestByIdFailure = (error) => {
  return {
    type: types.FETCH_REQUEST_BY_ID_FAILURE,
    payload: error,
  };
};
export const fetchRequestById = (id) => (dispatch) => {
  dispatch(fetchRequestByIdRequest());
  requestService
    .fetchRequesById(id)
    .then((data) => {
      dispatch(fetchRequestByIdSuccess(data));
    })
    .catch((error) => dispatch(fetchRequestByIdFailure(error)));
};


export const setRequest = (data) => {
  return {
    type: SET_REQUEST,
    payload: data,
  };
};


export const setRequestCertificate = (data) => {
  return {
    type: SET_REQUEST_CERTIFICATE,
    payload: data,
  };
}
// DOCUMENT DETAIL//
const fetchDocumentRequestByIdRequest = () => {
  return {
    type: types.FETCH_DOCUMENT_BY_ID_REQUEST,
  };
};

const fetchDocumentRequestByIdSuccess = (data) => {
  return {
    type: types.FETCH_DOCUMENT_BY_ID_SUCCESS,
    payload: data,
  };
};

const fetchDocumentRequestByIdFailure = (data) => {
  return {
    type: types.FETCH_DOCUMENT_BY_ID_FAILURE,
    payload: data,
  };
};

export const fetchDocumentRequestById = (id, fileName) => (dispatch) => {
  dispatch(fetchDocumentRequestByIdRequest());
  requestService
    .fetchDocumentRequestById(id, fileName)
    .then((data) => dispatch(fetchDocumentRequestByIdSuccess(data)))
    .catch((error) => dispatch(fetchDocumentRequestByIdFailure(error)));
};

// CERTIFICATE//
const fetchCertificateRequestByIdRequest = () => {
  return {
    type: types.FETCH_CERTIFICATE_BY_ID_REQUEST,
  };
};

const fetchCertificateRequestByIdSuccess = (data) => {
  return {
    type: types.FETCH_CERTIFICATE_BY_ID_SUCCESS,
    payload: data,
  };
};

const fetchCertificateRequestByIdFailure = (data) => {
  return {
    type: types.FETCH_CERTIFICATE_BY_ID_FAILURE,
    payload: data,
  };
};

export const fetchCertificateRequestById = (id, requestType) => (dispatch) => {
  dispatch(fetchCertificateRequestByIdRequest());
  requestService
    .fetchCertificateRequestById(id, requestType)
    .then((data) => dispatch(fetchCertificateRequestByIdSuccess(data)))
    .catch((error) => dispatch(fetchCertificateRequestByIdFailure(error)));
};

export const cleanDocumentRequestById = () => {
  return {
    type: CLEAR_DOCUMENT_REQUEST_BY_ID,
  };
};
export const cleanCertificateRequestById = () => {
  return {
    type: CLEAR_CERTIFICATE_REQUEST_BY_ID,
  };
};

export const setRequestDetailUrlFromEmail = (url) => {
  //Guardo si viene desde una URL externa, un link (history.location.state),
  //al clickear enlace desde mail, para redirigir luego a detalle de solicitud
  return {
    type: SET_REQUEST_DETAIL_URL_FROM_EMAIL,
    payload: { url },
  };
};

// SEARCH//
const fetchRequestBySearchRequest = () => {
  return {
    type: types.FETCH_REQUEST_BY_SEARCH_REQUEST,
  };
};

const fetchRequestBySearchSuccess = (data) => {
  return {
    type: types.FETCH_REQUEST_BY_SEARCH_SUCCESS,
    payload: data,
  };
};

const fetchRequestBySearchFailure = (data) => {
  return {
    type: types.FETCH_REQUEST_BY_SEARCH_FAILURE,
    payload: data,
  };
};

export const cleanDocumentRequestBySearch = () => {
  return {
    type: CLEAR_REQUEST_BY_SEARCH,
  };
};

export const fetchRequestBySearch = (query, page) => (dispatch) => {
  dispatch(fetchRequestBySearchRequest());
  requestService
    .fetchRequestBySearch(query, page)
    .then((data) => dispatch(fetchRequestBySearchSuccess(data)))
    .catch((error) => dispatch(fetchRequestBySearchFailure(error)));
};

// DOCUMENT UPDATE STATUS (CANCELED) //
const saveRequestStatusRequest = () => {
  return {
    type: types.SAVE_REQUEST_STATUS_REQUEST,
  };
};

const saveRequestStatusSuccess = (data) => {
  return {
    type: types.SAVE_REQUEST_STATUS_SUCCESS,
    payload: data,
  };
};

const saveRequestStatusFailure = (error) => {
  return {
    type: types.SAVE_REQUEST_STATUS_FAILURE,
    payload: error,
  };
};

export const saveRequestStatus = (id, data) => (dispatch) => {
  dispatch(saveRequestStatusRequest());
  return requestService
    .saveRequestStatus(id, data)
    .then(() => dispatch(saveRequestStatusSuccess(data)))
    .catch((error) => dispatch(saveRequestStatusFailure(error)));
};

const sendRequestNotificationToUserRequest = () => {
  return {
    type: types.SEND_USER_NOTIFICATION_REQUEST_REQUEST,
  };
};

const sendRequestNotificationToUserSuccess = (data) => {
  return {
    type: types.SEND_USER_NOTIFICATION_REQUEST_SUCCESS,
    payload: data,
  };
};

const sendRequestNotificationToUserFailure = (error) => {
  return {
    type: types.SEND_USER_NOTIFICATION_REQUEST_FAILURE,
    payload: error,
  };
};

export const sendRequestNotificationToUser = (id) => (dispatch) => {
  dispatch(sendRequestNotificationToUserRequest());
  return requestService
    .sendRequestUserNotification(id)
    .then((response) => dispatch(sendRequestNotificationToUserSuccess(response)))
    .catch((error) => dispatch(sendRequestNotificationToUserFailure(error)));
};

// /* UPDATE REQUEST FOR NATURAL PERSON */
// const updateNaturalPersonOrderRequest = () => {
//   return {
//     type: types.UPDATE_NATURAL_PERSON_ORDER_REQUEST,
//   };
// };
// const updateNaturalPersonOrderSuccess = (data) => {
//   return {
//     type: types.UPDATE_NATURAL_PERSON_ORDER_SUCCESS,
//     payload: data,
//   };
// };
// const updateNaturalPersonOrderFailure = (error) => {
//   return {
//     type: types.UPDATE_NATURAL_PERSON_ORDER_FAILURE,
//     payload: error,
//   };
// };
// export const updateNaturalPersonOrder = (data, id) => (dispatch) => {
//   dispatch(updateNaturalPersonOrderRequest());
//   return requestService
//     .updateNaturalPersonOrder(data, id)
//     .then((response) => dispatch(updateNaturalPersonOrderSuccess(response)))
//     .catch((error) => dispatch(updateNaturalPersonOrderFailure(error)));
// };

/* UPDATE REQUEST FOR LEGAL PERSON */
// const updateLegalPersonRequestRequest = () => {
//   return {
//     type: types.UPDATE_LEGAL_PERSON_ORDER_REQUEST,
//   };
// };
// const updateLegalPersonRequestSuccess = (data) => {
//   return {
//     type: types.UPDATE_LEGAL_PERSON_ORDER_SUCCESS,
//     payload: data,
//   };
// };
// const updateLegalPersonRequestFailure = (error) => {
//   return {
//     type: types.UPDATE_LEGAL_PERSON_ORDER_FAILURE,
//     payload: error,
//   };
// };
// export const updateLegalPersonRequest = (data, id) => (dispatch) => {
//   dispatch(updateLegalPersonRequestRequest());
//   return requestService
//     .updateLegalPersonRequest(data, id)
//     .then((response) => dispatch(updateLegalPersonRequestSuccess(response)))
//     .catch((error) => dispatch(updateLegalPersonRequestFailure(error)));
// };

import { useState, useEffect, useCallback } from "react";
import { DOC_MAX_SIZES } from "../constants";

export const useModal = (state, trigger, path, history, data, callback) => {
  // export const useModal = (state, trigger, path, history) => {
  let [showModal, setShowModal] = useState(state);
  let [showEditModal, setShowEditModal] = useState(state);
  useEffect(() => {
    trigger && setShowModal(true);
  }, [trigger]);

  const hideModal = () => {
    setShowModal(false);
  };
  const onConfirmRedirect = () => {
    history.push(path);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const openEditModal = () => {
    setShowEditModal(true);
  };

  const hideEditModal = () => {
    setShowEditModal(false);
  };

  const onConfirmCallback = () => {
    setShowModal(false);
    callback(data).then((res) => {
      if (res.type.endsWith("SUCCESS")) {
        setTimeout(function () {
          history.push(path);
        }, 4000);
      }
    });
  };
  return {
    showModal,
    showEditModal,
    setShowModal,
    hideModal,
    onConfirmRedirect,
    openModal,
    toggleModal,
    openEditModal,
    hideEditModal,
    onConfirmCallback,
  };
};

export const useFiles = (type) => {
  let fileStructure = {};
  switch (type) {
    case "person":
      fileStructure = {
        person_national_id_front_doc: "",
        person_national_id_rear_doc: "",
        pvp_doc: "",
      };
      break;
    case "legal":
      fileStructure = {
        representative_national_id_front_doc: "",
        representative_national_id_rear_doc: "",
        pvp_doc: "",
        company_structure_doc: "",
      };
      break;
    case "client":
      fileStructure = {
        logo: "",
      };
      break;
    case "agreements":
      fileStructure = {
        doc: "",
      };
      break;
    case "admin_doc":
      fileStructure = {
        doc: "",
      };
      break;
    default:
      fileStructure = {};
      break;
  }

  const [files, setFiles] = useState(fileStructure);
  const [size, setSize] = useState([]);
  const [sizeExceededError, setSizeExceededError] = useState(false);

  const handleFileChange = (e, setFieldValue, setErrors) => {
    if (e.target.files.length) {
      //verifico tipo y peso
      let { isValidSize, isValidtype, needCompresion, isPDF } =
        checkIsValidFile(e, DOC_MAX_SIZES[e.target.name]);
      if (isValidtype && isValidSize) {
        //guardo valores locales para que se vea el nombre del archivo al cargar
        setFiles({
          ...files,
          [e.target.name]: fileNameHandler(e.target.files[0].name),
        });
        let keyName = e.target.name;
        let filesSelected = e.target.files;
        if (filesSelected.length > 0) {
          let fileToLoad = filesSelected[0];

          //funcion para pasar file capturado a base64. Se retorna una promesa, pq el metodo filereader.onLoad es asincrono!
          function filePromise(file) {
            return new Promise((res, rej) => {
              let fileReader = new FileReader();
              fileReader.onload = (fileLoadedEvent) => {
                res(fileLoadedEvent.target.result);
              };

              fileReader.readAsDataURL(file);
            });
          }

          filePromise(fileToLoad).then((result) => {
            if (needCompresion && !isPDF) {
              resizeBase64Img(result).then((result) => {
                //ejecuto funcion reductora
                setFieldValue(keyName, result); //seteo valor en formik
                setSizeExceededError(false);
              });
            } else {
              setFieldValue(keyName, result); //seteo valor en formik
              setSizeExceededError(false);
            }
            //resuelvo resultado con archivo pasado a b64
          });
        }
      } else {
        if (!isValidSize) {
          //si archivo excede el tamaño
          setErrors({ [e.target.name]: "Excede el máximo permitido" });
          setSizeExceededError(true);
          setFiles({
            //limpio en caso en que ya exista un archivo previamente
            ...files,
            [e.target.name]: " ",
          });
        }
        if (!isValidtype) {
          //si el archivo no es de un tipo valido
          setErrors({ [e.target.name]: "Tipo de archivo inválido" });
          setSizeExceededError(false);
          setFiles({
            ...files,
            [e.target.name]: " ",
          });
        }
      }
    }
  };

  //funcion para chequear tipo y peso
  const checkIsValidFile = (e, maxSize) => {
    const referenceSize =
      Math.round((e.target.files[0].size / 1024000) * 10) / 10;
    let file = e.target.files ? e.target.files[0].type.split("/")[1] : [];
    let filetypes = new RegExp("/|jpeg|png|pdf|/");
    let pdfType = new RegExp("/|pdf|/");
    let isValidSize = false;
    let isValidtype = filetypes.test(file);
    let isPDF = pdfType.test(file);
    //REVISAR FUNCION REDUCTORA
    // if (referenceSize < maxSize * 1.46 && !isPDF) {
    if (referenceSize < maxSize && !isPDF) {
      isValidSize = true;
      setSize([...size, { key: e.target.name, size: referenceSize }]);
    } else if (isPDF && referenceSize < maxSize) {
      isValidSize = true;
      setSize([...size, { key: e.target.name, size: referenceSize }]);
    }
    let needCompresion = referenceSize > maxSize;

    let fileStatus = {
      isValidtype: isValidtype,
      isValidSize: isValidSize,
      needCompresion: needCompresion,
      isPDF: isPDF,
    };
    return fileStatus;
  };

  const resizeBase64Img = (base64) => {
    return new Promise((resolve, reject) => {
      let img = document.createElement("img");
      img.src = base64;

      img.onload = function () {
        const elem = document.createElement("canvas");
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        let width = img.width * 0.5;
        let height = img.height * 0.5;
        elem.width = width;
        elem.height = height;
        ctx.fillStyle = "#ffffff00";
        ctx.fillRect(0, 0, img.width, img.height);
        ctx.scale(width / img.width, height / img.height);
        ctx.drawImage(img, 0, 0, width, height);
        resolve(elem.toDataURL()); //devuelve unn b64
      };
    });
  };

  const sizeCalculator = useCallback(
    (size, maxAllowed, setFieldValue) => {
      const sizeAcum = size.reduce((acum, { size }) => acum + size, 0);
      //si peso total no super maximo por envio, seteo false, es decir NO necesita multiples envios
      if (sizeAcum < maxAllowed) {
        setFieldValue("filesBuffer", false);
      } else {
        //si supera, primero ordeno los archivos
        let sortedArray = size
          .slice()
          .sort((a, b) => (a.size > b.size ? 1 : -1));
        //ejecuto funcion recursiva, para crear N sub arrays, cada posicion representa UN envio al back
        let filesBuffer = recursiveFilesFn(sortedArray, maxAllowed);
        //lo guardo en formik como un value mas
        setFieldValue("filesBuffer", filesBuffer);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size]
  );

  let recursiveFilesFn = (array, maxAllowed, files = []) => {
    let keysArray = [];
    let localTotal = maxAllowed;
    let filesPack = files;
    array.forEach((element, i) => {
      localTotal = localTotal - element.size;
      if (localTotal > 0) {
        keysArray.push(element.key);
        array.splice(i, 1);
      } else {
        filesPack.push([element.key]);
        array.splice(i, 1);
      }
    });
    keysArray.length && filesPack.push(keysArray);
    if (array.length) return recursiveFilesFn(array, maxAllowed, filesPack);
    else return filesPack;
  };

  const fileNameHandler = (name) => {
    let fileName;
    if (name.toString().length > 15) {
      fileName = `${name.substring(0, 20)}...`;
      return fileName;
    } else return name;
  };

  return {
    files,
    size,
    setFiles,
    handleFileChange,
    sizeExceededError,
    sizeCalculator,
    checkIsValidFile,
    resizeBase64Img,
  };
};

import types from "../../actions/agreementsActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const newAgreement = (
    state = {
        data: {},
        status: FETCH_STATUS.NOT_LOADED,
        error: "",
    },
    action
) => {
    const { payload: { data, error } = {} } = action;
    switch (action.type) {
        case types.SAVE_NEW_AGREEMENT_REQUEST:
            return {
                ...state,
                status: FETCH_STATUS.LOADING,
                error: null,
            };
        case types.SAVE_NEW_AGREEMENT_SUCCESS:
            return {
                ...state,
                data,
                status: FETCH_STATUS.LOADED,
            };
        case types.SAVE_NEW_AGREEMENT_FAILURE:
            return {
                ...state,
                status: FETCH_STATUS.FAILED,
                error,
            };

        default:
            return state;
    }
};

export default newAgreement;
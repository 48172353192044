import { createActionTypes } from "../../../utils";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...createActionTypes("USER", "LOGIN_USER"),
  ...createActionTypes("USER", "LOGOUT_USER"),
  ...createActionTypes("USER", "GET_NEW_TOKEN"),
  ...createActionTypes("USER", "GET_NEW_PASSWORD"),
  ...createActionTypes("USER", "SET_NEW_PASSWORD"),
  ...createActionTypes("USER", "SET_CHANGE_PASSWORD"),
};

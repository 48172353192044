import types, { CLEAR_ACTIVITIES_BY_SEARCH } from "../../actions/activitiesActions/types";
import { FETCH_STATUS } from "../../../constants";

export const activitiesSearch = (
  state = {
    data: {},
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error} = {} } = action;
  switch (action.type) {
    case types.FETCH_ACTIVITIES_BY_SEARCH_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.FETCH_ACTIVITIES_BY_SEARCH_SUCCESS:
      return {
        ...state,
        data: data,
        status: FETCH_STATUS.LOADED,
      };
    case types.FETCH_ACTIVITIES_BY_SEARCH_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
    case CLEAR_ACTIVITIES_BY_SEARCH:
        return {
          data: {},
          status: FETCH_STATUS.NOT_LOADED,
        };
    default:
      return state;
  }
};

/* eslint-disable no-useless-escape */
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;


//USER PATHS
const LOGIN_PATH = "/auth/login/";
const LOGOUT_PATH = "/auth/logout/";
const USER_PATH = "/users/";
const PROFILE_PATH = "/users/profile/";
const REFRESH_TOKEN_PATH = "/auth/refresh/";
const RESET_PASSWORD = "/auth/password/reset/";
const CHANGE_PASSWORD = "/auth/change-password/";
const RESET_PASSWORD_CONFIRM = "/auth/password/reset/confirm/";

//REQUEST PATHS
const REQUESTS_PATH = "/requests/";

//CLIENTS PATH
const CLIENTS_PATH = "/clients/company/";

//ACTIVITIES PATH
const ACTIVITIES_PATH = "/activities/";

//ACTIVITIES PATH
const PRODUCTS_PATH = "/products/";

//CLIENTS PREFERENCES PATH

//ENDPOINTS
export const LOGIN_ENDPOINT = BASE_URL + LOGIN_PATH;
export const LOGOUT_ENDPOINT = BASE_URL + LOGOUT_PATH;
export const REFRESH_TOKEN_ENDPOINT = BASE_URL + REFRESH_TOKEN_PATH;
export const USER_PROFILE_ENDPOINT = BASE_URL + PROFILE_PATH;
export const USERS_ENDPOINT = BASE_URL + USER_PATH;
export const REQUESTS_ENDPOINT = BASE_URL + REQUESTS_PATH;
export const RESET_PASSWORD_ENDPOINT = BASE_URL + RESET_PASSWORD;
export const RESET_PASSWORD_CONFIRM_ENDPOINT = BASE_URL + RESET_PASSWORD_CONFIRM;
export const CLIENTS_PATH_ENDPOINT = BASE_URL + CLIENTS_PATH;
export const ACTIVITIES_PATH_ENDPOINT = BASE_URL + ACTIVITIES_PATH;
export const SUSCRIPTIONS_PATH_ENDPOINT = BASE_URL + CLIENTS_PATH;
export const PRODUCTS_PATH_ENDPOINT = BASE_URL + PRODUCTS_PATH;
export const CHANGE_PASSWORD_ENDPOINT = BASE_URL + CHANGE_PASSWORD;
// export const REQUESTS_BY_ID_ENDPOINT = BASE_URL + REQUESTS_BY_ID_PATH;
//UTILS
export const REGEX = {
  ALPHABETIC: /^[a-zA-Z]+$/,
  ALPHABETIC_WITH_SPACE: /^[-ñÑa-zA-Zñáéíóú ' ]*$/,
  ALPHANUMERIC: /^[a-zA-Z0-9]+$/,
  ALPHANUMERIC_WHIT_ONE_LETTER_AT_LEAST: /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/,
  ALPHANUMERIC_WITH_SPACE: /^[ñÑa-zA-Z0-9- ]+$/,
  ALPHANUMERIC_WITH_SPACE_AND_SPECIAL_CHARACTERS: /^[ñÑa-zA-Zñáéíóú0-9-.,-°/´()–º ]+$/,
  ALPHANUMERIC_WITH_DOT: /^[ñÑa-zA-Z0-9-.]+$/,
  ALPHANUMERIC_FOR_LEGAL_PERSON: /^[ñÑa-zA-Zñáéíóú0-9-. ,-–]+$/,
  TELEPHONE_NUMBER_WITHOUT_ZERO_START: /^[1-9][0-9]*$/,
  TELEPHONE_NUMBER: /^[+](?:34)([1-9][0-9]*)$/,
  MOBILE_34: /^([+](?:34)([6-7][0-9]*))$/,
  // MOBILE_NUMBER: /^([6-7][0-9]*)$/,
  MOBILE_NUMBER: /^([0-9]*)$/,
  TELEPHONE_NUMBER_ONLY_NUMBERS: /^([1-9][0-9]*)$/,
  NAMES_REGEX: /^[a-zA-Z0-9 ']+$/,
  EMAIL_REGEX_SCHEMA: /^(?=.{1,254}$)((?=.{1,64}@)[ñÑa-zA-Z0-9-`~!@#$%^&*?/()_|+\-=;:'"´¨,.<>\{\}\[\]\\\/]+\.[A-Za-z]{2,4},?)$/,
  NUMERIC: /^[0-9]+$/,
  NUMERIC_OR_EMPTY: /^[0-9]+$|^$/,
  SPANISH_PASSPORT: /^[a-zA-Z]{3}[0-9]{6}[a-z]?$/i,
  NIF: /^(\d{8})([a-zA-Z])$/,
  NIE: /^[XYZ]\d{7,8}[a-zA-Z]$/,
  CIF: /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/,
  CIF_WITHOUT_H: /^(?![hH]).*[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/,
  POSTAL_CODE: /0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}/,
  URL_ID: /([A-Za-z]+[0-9]|[0-9]+[A-Za-z])[A-Za-z0-9 -]*$/,
};

export const FETCH_STATUS = {
  NOT_LOADED: "NOT_LOADED",
  LOADING: "LOADING",
  LOADED: "LOADED",
  FAILED: "FAILED",
};
export const TIMEOUT = {
  FIFTEEN_MINUTES: 1 * 60 * 1000,
};

export const TYPEOPTIONS = {
  vida_laboral: "Vida Laboral",
  cirbe: "Cirbe",
  renta: "Renta",
  modelo_347: "Modelo 347",
  sociedades: "Sociedades",
  iva: "Iva",
  modelo_390: "Modelo 390",
  modelo_303: "Modelo 303",
  modelo_200: "Modelo 200",
  pensiones: "Integral de Prestaciones de la S.S.", 
  base_cotizacion: "Bases de cotización",
  situacion_censal: "Identificación Fiscal",

};
export const DOC_TYPES = {
  person_national_id_front_doc: "Descargar DNI anverso",
  person_national_id_rear_doc: "Descargar DNI reverso",
  representative_national_id_front_doc: "Descargar DNI anverso",
  representative_national_id_rear_doc: "Descargar DNI reverso",
  company_structure_doc: "Descargar escrituras",
  pvp_doc: "Descargar certificado de identificación",
  registro_nota_simple_doc: "Descargar Nota simple"
};

export const PERSON_TYPE_IDS = {
  PJ: 1,
  PF: 2,
};

export const PERSON_TYPES = {
  personaJuridica: "Persona Juridica",
  personaFisica: "Persona Física",
};

export const REQUEST_STATUS = {
  iniciada: "Iniciada",
  aceptadoTyC: "Aceptado Tyc",
  pendiente: "Pendiente Cliente",
  aprobada: "Pendiente Cliente",
  emitida: "Aceptado cliente",
  publicada: "Aceptado cliente",
  completada: "Completada",
  cerrada: "Completada",
  rechazada: "Rechazada",
  cancelada: "Cancelada",
  suspender: "Suspender",
  activar: "Activar",
  descargar: "Descargar",
  activo: "Activo",
  suspendido: "Suspendido",
  sinEstado: "Sin estado",
  editar: "Editar",
  cancelar_solicitud: "Cancelar Solicitud",
  procesando: "Pendiente cliente",
  registradoPIN: "Registrado PIN",
  incompleta: "Incompleta",
  revocada: "Revocada",
  eliminar: "Eliminar",
  simplificado: "Simplificado"

};

export const STATUS_LIST = {
  INCOMPLETE: "INCOMPLETE",
  STARTED: "STARTED",
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  EMITTED: "EMITTED",
  PUBLISH: "PUBLISH",
  APPROVED: "APPROVED",
  COMPLETED: "COMPLETED",
  CLOSED: "CLOSED",
  REJECTED: "REJECTED",
  CANCELLLEDBYTIMEOUT: "CANCELLLEDBYTIMEOUT",
  CANCELLED: "CANCELLED",
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
  ACTIVE: "ACTIVE",
  DOWNLOAD: "DOWNLOAD",
  EDIT: "EDIT",
  CHANGE_STATUS: "CHANGE_STATUS",
  PROCESSING: "PROCESSING",
  REVOKED: "REVOKED",
  DELETE: "DELETE",
};

export const CERTIFICATE_STATUS = {
  ENROLLREADY: "ENROLLREADY",
  CREATED: "CREATED",
  CANCELLED: "CANCELLED",
  ISSUED: "ISSUED"
}

export const DOCUMENT_TYPE = [
  { name: "DNI", key: "dni" },
  { name: "DNI permanente", key: "dni_permanente" },
  { name: "NIE", key: "tja_residencia" },
  // { name: "Tarjeta de residencia NO UE", key: "tja_residencia2" },
];

export const CHECKBOX_BUTTON = {
  name: {
    request_type: "request_type",
    communication_type: "communication_type",
    products_groups: "products_groups",
    position: "position",
    send_reseller_filled: "send_reseller_filled",
    type_id: "type_id",
  },
  className: "form-check-input",
  type: "checkbox",
  option: {
    cirbe: "cirbe",
    modelo347: "modelo_347",
    modelo390: "modelo_390",
    modelo303: "modelo_303",
    modelo200: "modelo_200",
    iva: "iva",
    sociedades: "sociedades",
    vidaLaboral: "vida_laboral",
    basesDeCotizacion: "base_cotizacion",
    situacionCensal: "situacion_censal",
    renta: "renta",
    email: "EMAIL",
    sms: "SMS",
    director: "director",
    supervisor: "supervisor",
    operador: "operador",
    product_cirbe: "sell_product_cirbe",
    product_modelo347: "sell_product_modelo_347",
    product_modelo390: "sell_product_modelo_390",
    pensiones: "pensiones",
    product_modelo303: "sell_product_modelo_303",
    product_modelo200: "sell_product_modelo_200",
    product_iva: "sell_product_iva",
    product_sociedades: "sell_product_sociedades",
    product_vidaLaboral: "sell_product_vida_laboral",
    product_renta: "sell_product_renta",
    product_pensiones: "sell_product_pensiones",
    product_bases_de_cotizacion: "sell_product_base_cotizacion",
    product_situacion_censal: "sell_product_situacion_censal",
    type_identity: "sell_product_type_identity", // Espera de backend
    signed_type_identity: "signed_type_identity",
    video_type_identity: "video_type_identity",
    pin_type_identity: "pin_type_identity"
  },
};

export const DOC_MAX_SIZES = {
  person_national_id_front_doc: 3,
  person_national_id_rear_doc: 3,
  pvp_doc: 3,
  representative_national_id_front_doc: 3,
  representative_national_id_rear_doc: 3,
  company_structure_doc: 4.4,
  doc: 3,
  admin_doc: 3,
  maxAllowed: 4.41,
};

export const TITLE_DICTIONARY = {
  "/requests/list/pending": "Solicitudes",
  "/requests/list/closed": "Solicitudes",
  "/requests/list/cancelled": "Solicitudes",
  "/requests/new/natural_person": "Nueva Solicitud",
  "/requests/new/legal_person": "Nueva Solicitud",
  "/requests/detail/:id": "Detalle de Solicitud",
  "/general_data/general_information": "Informacion General",
  "/general_data/profile_edit/": "Datos Generales - Editar",
  "/general_data/suscriptions": "Planes",
  "/general_data/activity": "Actividades",
  "/general_data/invoices": "Facturas",
  "/general_data/change_password": "Cambiar contraseña",
  "/admin/requests/list/started": "Solicitudes",
  "/admin/requests/list/pending-client": "Solicitudes",
  "/admin/requests/list/accepted-client": "Solicitudes",
  "/admin/requests/list/closed": "Solicitudes",
  "/admin/requests/list/cancelled": "Solicitudes",
  "/admin/invoices/list": "Facturas",
  "/admin/extras": "Extras",
  "/admin/extras/general_information": "Datos Generales.",
  "/admin/extras/profile_edit/": "Editar Datos Generales. ",
  "/admin/clients/list": "Clientes.",
  "/admin/clients/activity_list": "Acuerdos",
  "/admin/clients/detail/plans/": "Planes/Precios",
  "/admin/clients/detail/usage_data/": "Datos de uso",
  "/admin/clients/new/general_data": "Nuevo Cliente.",
  "/admin/clients/detail/invoices/": "Facturas",
  "/admin/clients/detail/associates/": "Asociados",
  "/admin/clients/detail/preferences/": "Preferencias",
  "/admin/clients/detail/activity_list": "Acuerdos",
  "/admin/clients/detail/plans": "Planes",
  "/admin/clients/detail/client_data/": "Datos de Cliente",
  "/admin/clients/detail/agreements/": "Acuerdos",
  "/admin/clients/invoices.": "Facturas",
  "/admin/clients/new/activity_list": "Acuerdos",
  "/admin/clients/new/plans": "Planes/Precios",
  "/admin/clients/new/invoices": "Facturas",
  "/admin/clients/new/associates": "Asociados",
  "/terms": "Terminos y condiciones de uso.",
};
export const PERMISSIONS = [
  "guardian.change_userobjectpermission",
  "rest_framework_api_key.delete_apikey",
  "cirboxcertificate.add_certificateorder",
  "guardian.delete_groupobjectpermission",
  "token_blacklist.delete_outstandingtoken",
  "admin.add_logentry",
  "plan.change_plan",
  "cirboxcertificate.sell_product_renta",
  "hola.delete_question",
  "sites.delete_site",
  "guardian.change_groupobjectpermission",
  "guardian.add_userobjectpermission",
  "cirboxclient.change_naturalclient",
  "customlogin.delete_organizationapikey",
  "guardian.delete_userobjectpermission",
  "cirboxcertificate.sell_product_vida_laboral",
  "cirboxclient.add_companycontactperson",
  "cirboxcertificate.view_cirboxcertificatereport",
  "cirboxcertificate.delete_certificateorder",
  "cirboxclient.view_naturalclient",
  "auth.change_permission",
  "cirboxclient.view_companyclient",
  "agreement.view_agreement",
  "auth.delete_group",
  "customlogin.view_organizationapikey",
  "customperson.view_company",
  "customperson.change_contactperson",
  "cirboxcertificate.add_cirboxcertificatereport",
  "cirboxclient.delete_companyclient",
  "token_blacklist.add_blacklistedtoken",
  "hola.add_question",
  "auth.add_group",
  "cirboxclient.change_companycontactperson",
  "plan.view_plan",
  "sessions.delete_session",
  "admin.view_logentry",
  "token_blacklist.delete_blacklistedtoken",
  "hola.change_question",
  "guardian.view_userobjectpermission",
  "customlogin.add_organizationapikey",
  "auth.view_permission",
  "customperson.change_company",
  "auth.change_group",
  "cirboxcertificate.add_cirboxcertificate",
  "rest_framework_api_key.change_apikey",
  "customperson.view_naturalperson",
  "customlogin.change_organizationapikey",
  "activity.add_orderactivity",
  "sites.add_site",
  "sites.change_site",
  "myprofile.change_user",
  "activity.delete_orderactivity",
  "customperson.delete_naturalperson",
  "contenttypes.view_contenttype",
  "sessions.view_session",
  "customperson.add_contactperson",
  "auth.view_group",
  "contenttypes.change_contenttype",
  "cirboxclient.add_companyclient",
  "token_blacklist.change_blacklistedtoken",
  "myprofile.view_user",
  "cirboxcertificate.view_cirboxcertificate",
  "token_blacklist.view_blacklistedtoken",
  "cirboxclient.delete_naturalclient",
  "sites.view_site",
  "customperson.delete_contactperson",
  "plan.delete_plan",
  "token_blacklist.add_outstandingtoken",
  "plan.add_plan",
  "customperson.change_naturalperson",
  "hola.view_question",
  "token_blacklist.view_outstandingtoken",
  "token_blacklist.change_outstandingtoken",
  "cirboxcertificate.change_certificateorder",
  "customperson.delete_company",
  "cirboxcertificate.view_certificateorder",
  "customperson.add_naturalperson",
  "rest_framework_api_key.add_apikey",
  "activity.view_orderactivity",
  "sessions.change_session",
  "cirboxclient.add_naturalclient",
  "sessions.add_session",
  "admin.delete_logentry",
  "contenttypes.add_contenttype",
  "auth.add_permission",
  "myprofile.add_user",
  "agreement.add_agreement",
  "cirboxcertificate.delete_cirboxcertificatereport",
  "agreement.delete_agreement",
  "auth.delete_permission",
  "myprofile.delete_user",
  "cirboxclient.delete_companycontactperson",
  "cirboxcertificate.change_cirboxcertificate",
  "customperson.view_contactperson",
  "cirboxcertificate.delete_cirboxcertificate",
  "admin.change_logentry",
  "customperson.add_company",
  "contenttypes.delete_contenttype",
  "activity.change_orderactivity",
  "agreement.change_agreement",
  "guardian.view_groupobjectpermission",
  "cirboxclient.change_companyclient",
  "rest_framework_api_key.view_apikey",
  "cirboxcertificate.change_cirboxcertificatereport",
  "cirboxclient.view_companycontactperson",
  "guardian.add_groupobjectpermission",
  "cirboxcertificate.sell_product_cirbe",
];
export const europeanTelCodes = [
  "34",
  "30",
  "31",
  "32",
  "33",
  "36",
  "39",
  "43",
  "44",
  "44",
  "45",
  "47",
  "48",
  "49",
  "350",
  "351",
  "352",
  "353",
  "354",
  "355",
  "356",
  "357",
  "358",
  "359",
  "370",
  "371",
  "372",
  "373",
  "375",
  "376",
  "377",
  "378",
  "380",
  "381",
  "381",
  "382",
  "385",
  "386",
  "387",
  "389",
  "407",
  "420",
  "421",
  "423",
  "441",
  "29839",
];

export const LABELS = {
  request: {
    tabs: {
      solicitudesStarted: "Solicitudes iniciadas",
      solicitudesPendientes: "Solicitudes pendientes",
      solicitudesCerradas: "Solicitudes completadas",
      solicitudesCanceladas: "Solicitudes canceladas",
      solicitudesPendientesCliente: "Solicitudes pendiente cliente",
      solicitudesAceptadasCliente: "Solicitudes aceptado cliente",
    },
    requestList: {
      tableHeader: {
        fecha: {
          name: "Fecha",
          dataField: "created",
        },
        solicitud: { name: "Solicitud", dataField: "serial" },
        nombreCompleto: {
          name: "Nombre Completo",
          dataField: "person_first_name",
        },
        tipoDeSolicitud: {
          name: "Tipo de Solicitud",
          dataField: "request_type",
        },
        document: { name: "NIF/CIF", dataField: "person_national_id" },

        estado: { name: "Estado", dataField: "status" },

        motivo: {
          name: "Motivo de cancelación",
          dataField: "cancellation_reason",
        },
      },
      status: {
        globalStarted: "STARTED",
        globalOpen: "global_open",
        globalPendingClient: "APPROVED,PENDING,PROCESSING",
        globalAcceptedClient: "EMITTED,PUBLISH",
        globalClosed: "global_closed&ordering=-datetime_close",
        globalCancelled: "CANCELLED,CANCELLLEDBYTIMEOUT,REJECTED,REVOKED",
        requestStatus: {
          loading: "LOADING",
        },
      },
      searchPlaceHolder: {
        buscarSolicitud: "Buscar solicitud...",
      },
    },
    requestDetail: {
      status: {
        processing: "PROCESSING",
        incomplete: "INCOMPLETE",
        started: "STARTED",
        success: "SUCCESS",
        pending: "PENDING",
        approved: "APPROVED",
        emitted: "EMITTED",
        publish: "PUBLISH",
        completed: "COMPLETED",
        closed: "CLOSED",
        rejected: "REJECTED",
        cancelled: "CANCELLED",
        cancelledByTimeOut: "CANCELLLEDBYTIMEOUT",
        revoked: "REVOKED",
        requestDetailStatus: {
          loading: "LOADING",
        },
      },
      modal: {
        message: {
          cerrarSolicitud: "¿Está seguro que desea cerrar la solicitud?",
        },
      },
      labels: {
        solicitud: "Solicitud",
        fechaDeSolicitud: "Fecha de solicitud",
        solicitante: "Solicitante",
        motivo: "Motivo:",
        estado: "Estado",
        informacionPersonal: "Información personal",
        nombreCompleto: "Nombre completo",
        primerApellido: "Primer apellido",
        segundoApellido: "Segundo apellido",
        dniNie: "DNI/NIE",
        tipoDocumento: "Tipo documento",
        numeroDocumento: "Número documento",
        fechaDeNacimiento: "Fecha de nacimiento",
        informacionDeContacto: "Información de contacto",
        telefonoMovil: "Telefono Movil",
        telefonoFijo: "Telefono Fijo",
        correoElectronico: "Correo electrónico",
        correoEnvioInforme: "Correo electrónico para envio de informe",
        direccion: "Direccion",
        resendNotification: "Reenviar notificación",
        identification: "Identificación",
        signed_identification: "ID Firmada",
        video_identification: "ID Video"
      },
    },
  },
  activities: {
    activitiesList: {
      status: {
        activitiesStatus: {
          loading: "LOADING",
        },
      },
      searchPlaceHolder: {
        buscarActividades: "Buscar actividades...",
      },
    },
  },

  suscriptions: {
    suscriptionsList: {
      status: {
        suscriptionsStatus: {
          loading: "LOADING",
        },
      },
    },
  },

  invoices: {
    invoicesList: {
      status: {
        invoicesStatus: {
          loading: "LOADING",
        },
      },
    },
  },

  modalForm: {
    titles: {
      nuevoContacto: "Nuevo contacto",
      agregarNuevoContacto: "Llene los campos para agregar un nuevo contacto",
      edicionContacto: "Editar contacto",
      editarContacto: "Llene los campos para modificar un contacto",
      nuevoAsociado: "Nuevo asociado",
      agregarNuevoAsociado: "Llene los campos para agregar un nuevo asociado",
      edicionAsociado: "Llene los campos para modificar un asociado",
      editarAsociado: "Editar asociado",
    },
    labels: {
      nombre: "Nombre",
      primerApellido: "Apellidos",
      segundoApellido: "Segundo Apellido",
      rol: "Rol",
      tipoDeComunicacion: "Tipo de comunicación",
      email: "Email",
      telefonoMovil: "Teléfono Móvil",
      telefonoFijo: "Teléfono Fijo",
      manager: "Manager",
      posicion: "Posición",
      idTypeAllowed: "Video identificación",
      videoId: "Video ID"
    },
    button: {
      agregar: "Agregar contacto",
      confirmar: "Confirmar",
      cancelar: "Cancelar",
      editar: "Editar contacto",
      editarAsociado: "Editar asociado",
    },
  },

  newRequest: {
    spinnerMessage: "Generando solicitud, aguarde...",
    modalMessage: "Existen campos incompletos, por favor revise",
    identificacionErrorMessage: "Documento ingresado no es valido",
    fechaDeNacimientoErrorMessage: "Fecha de nacimiento inválida",
    fechaDeValidezErrorMessage: "Fecha de validez inválida",
    fechaDeValidezErrorMessagePermanente: "Se trata de un DNI Permanente. Por favor, seleccione en Identificación - DNI Permanente y registre la fecha de emisión",
    fechaDeEmisionErrorMessage: "Se trata de un DNI de tipo Permanente por lo que debe introducir la Fecha de Emisión",
    fileSize: "Excede el máximo permitido",
    singleFileSize: "El tamaño por archivo no debe ser superior a ",
    type: {
      person: "person",
      legal: "legal",
    },
    naturalPerson: {
      labels: {
        cirbe: "Cirbe",
        vidaLaboral: "Vida Laboral",
        basesDeCotizacion: "Bases de cotización",
        situacionCensal: "Identificación Fiscal",
        renta: "Renta",
        modelo347: "Modelo 347",
        nombre: "Nombre",
        primerApellido: "Primer Apellido",
        segundoApellido: "Segundo Apellido",
        identificacion: "Identificación",
        numeroDeIdentificacion: "Numero de identificación",
        telefonoMovil: "Telefono móvil",
        fechaDeNacimiento: "Fecha de Nacimiento",
        fechaDeValidez: "Fecha de validez",
        fechaDeEmision: "Fecha de emisión",
        correoElectronico: "Correo Electrónico",
        correoElectronicoParaEnvio: "Correo electrónico para envio de informe",
        cargaDocumentos: "Carga de documentos solicitados",
        formatos:
          "formatos admitidos: JPG, PNG, PDF. El tamaño por archivo no debe ser superior a 3MB",
        dni: "DNI",
        caraDelantera: "DNI anverso",
        caraTrasera: "DNI reverso",
        ambasCaras: "Ambas caras del DNI estan en un solo archivo",
        pvp: "Identificación",
        enviarSolicitud: " Enviar Solicitud",
        modelo390: "Modelo 390",
        iva: "IVA",
        modelo303: "Modelo 303",
        pensiones: "Integral de Prestaciones de la S.S.",
        avisoTamaño: "Excede el máximo permitido",
        pasaporte: "PASAPORTE",
        pasaporteAnverso: "PASAPORTE anverso",
        pasaporteReverso: "PASAPORTE reverso",
      },
    },
    legalPerson: {
      labels: {
        cirbe: "Cirbe",
        vidaLaboral: "Vida Laboral",
        basesDeCotizacion: "Bases de cotización",
        renta: "Renta",
        modelo347: "Modelo 347",
        nombreEmpresa: "Nombre de la empresa",
        cifEmpresa: "CiF de la empresa",
        nombreRepresentante: "Nombre del representante",
        primerApellido: "Primer apellido",
        segundoApellido: "Segundo Apellido",
        identificacion: "Identificación",
        numeroDeIdentificacion: "Numero de identificación",
        telefonoMovil: "Telefono móvil",
        fechaDeNacimiento: "Fecha de Nacimiento",
        correoElectronico: "Correo Electrónico",
        correoElectronicoParaEnvio: "Correo electrónico para envio de informe",
        cargaDocumentos: "Carga de documentos solicitados",
        formatos:
          "formatos admitidos: JPG, PNG, PDF. El tamaño por archivo no debe ser superior a 3MB, excepto Escrituras 4.4MB",
        dni: "DNI",
        caraDelantera: "DNI anverso",
        caraTrasera: "DNI reverso",
        escrituras: "Escrituras",
        ambasCaras: "Ambas caras del DNI estan en un solo archivo",
        pvp: "Identificación",
        enviarSolicitud: " Enviar Solicitud",
        modelo390: "Modelo 390",
        modelo303: "Modelo 303",
        modelo200: "Modelo 200",
        iva: "IVA",
        sociedades: "Sociedades",
        avisoTamaño: "Excede el máximo permitido",
      },
    },
  },

  forgotPassword: {
    modalMessage: "Ha sido enviado un email con instrucciones para finalizar el proceso. Por favor, consulte su buzón de correo",
    labels: {
      ingreseCorreo: `Ingrese el correo electrónico registrado y siga los pasos
              enviados a su correo electrónico.`,
      restablecerContraseña: "Reestablecer contraseña",
      volver: "Volver",
      derechos: "© Derechos reservados Pinpass 2023",
      correoElectronico: "Correo electrónico",
    },
  },
  clients: {
    clientList: {
      status: {
        loading: "LOADING",
        notLoaded: "NOT_LOADED",
      },
    },
    newCLient: {
      clientData: {
        labels: {
          imagenDeEmpresa: "Imagen de la empresa",
          formatosDeArchivo:
            "Formatos: png, jpg, Tamaño recomendadado: 48px con 48px, ",
          nombreComercial: "Nombre comercial",
          nombreLegal: "Nombre legal",
          nombre: "Nombre",
          cif: "Nº CIF empresa",
          direccion: "Dirección",
          ciudad: "Ciudad",
          contacto: "Contacto",
          provincia: "Provincia",
          primerApellido: "Primer Apellido",
          segundoApellido: "Segundo Apellido",
          rol: "Rol",
          tipoDeComunicacion: "Tipo de comunicación",
          email: "Email",
          telefonoMovil: "Teléfono Móvil",
          telefono: "Teléfono",
          crearNuevo: " Crear nuevo cliente",
          codigoPostal: "Codigo Postal",
          sms: "SMS",
          videoId: "Video ID",
          idTypeAllowed: "Video identificación"
        },
      },
      agreements: {
        labels: {
          acuerdos: "Acuerdos",
          cargaNuevoAcuerdo: "Cargar nuevo acuerdo",
          descripcion: "Descripcion",
          confirmar: "Confirmar",
          cancelar: "Cancelar",
        },
        tableHeader: {
          fecha: { name: "Fecha", dataField: "created" },
          fechaActualizacion: { name: "Actualizado", dataField: "last_update" },
          descripción: { name: "Descripción", dataField: "text" },
          accion: { name: "Acción", dataField: "doc" },
        },

        newAgreement: {
          labels: {
            nuevoAcuerdo: "Nuevo Acuerdo",
            lleneCampoDescripcion:
              "Llene el campo de descripción y carge el nuevo acuerdo.",
            descripcion: "Descripcion",
            cargar: "Cargar archivo del acuerdo",
          },
          type: {
            agreements: "agreements",
          },
        },
      },
      suscriptions: {
        labels: {
          suscriptions: "Planes",
        },
      },
    },
  },
  changePassword: {
    labels: {
      cambioClave: "Cambio de contraseña",
      claveActual: "Ingrese su contraseña actual",
      claveNueva: "Ingrese su nueva contraseña",
      claveNuevaReingresar: "Reingrese su nueva contraseña",
      confirmar: "Confirmar",
    },
  },
  preferences: {
    labels: {
      cirbe: "Cirbe",
      vidaLaboral: "Vida Laboral",
      basesDeCotizacion: "Bases de cotización",
      situacionCensal: "Identificación Fiscal",
      renta: "Renta",
      modelo347: "Modelo 347",
      modelo390: "Modelo 390",
      modelo303: "Modelo 303",
      modelo200: "Modelo 200",
      pensiones: "Integral de Prestaciones de la S.S.",
      iva: "IVA",
      sociedades: "Sociedades",
      tipoDeComunicacion: "Tipo de comunicación",
      tipoDeProductos: "Productos",
      SMS: "SMS",
      EMAIL: "EMAIL",
      supervisor: "Supervisor",
      director: "Director",
      operador: "Operador",
      position: "Posición",
      guardar: "Guardar",
      type_identity: "Tipo de identificacion",
      signed_type_identity: "ID Firmada",
      video_type_identity: "ID Video",
      pin_type_identity: "Cl@ve PIN"
    },
  },
};

import request from "../actions/requestActions/types";
import profile from "../actions/profileActions/types";
import clients from "../actions/clientsActions/types";
import agreements from "../actions/agreementsActions/types";
import users from "../actions/userActions/types";
import requestAdmin, {
  APPROVE_REQUEST_VALIDATION_FAILURE,
} from "../actions/requestAdminActions/types";
import certificateAdmin from "../actions/digitalCertificateActions/types";
import documentsAdmin from "../actions/documentsActions/types";
export const successMessages = {
  [request.SAVE_LEGAL_PERSON_ORDER_SUCCESS]: "Solicitud creada exitosamente.",
  [request.SAVE_NATURAL_PERSON_ORDER_SUCCESS]: "Solicitud creada exitosamente.",
  [profile.SAVE_PROFILE_EDITION_SUCCESS]: "Perfil modificado con éxito.",
  [clients.DELETE_CONTACT_SUCCESS]: "Contacto eliminado con éxito.",
  [clients.SAVE_NEW_CONTACT_SUCCESS]: "Contacto creado con éxito.",
  [clients.SAVE_NEW_CLIENT_SUCCESS]: "Cliente creado con éxito.",
  [agreements.SAVE_NEW_AGREEMENT_SUCCESS]: "Acuerdo creado con éxito",
  [users.SET_CHANGE_PASSWORD_SUCCESS]: "Contraseña modificada con éxito.",
  [clients.EDIT_CONTACT_SUCCESS]: "Cliente editado con éxito.",
  [clients.EDIT_CLIENT_PREFERENCES_SUCCESS]: "Cliente editado con éxito.",
  [request.SAVE_REQUEST_STATUS_SUCCESS]: "Solicitud cancelada con éxito.",
  [agreements.DELETE_AGREEMENT_SUCCESS]: "Acuerdo eliminado con éxito.",
  [requestAdmin.APPROVE_REQUEST_SUCCESS]: "Solicitud de aprobación enviada",
  [requestAdmin.UPDATE_REQUEST_SUCCESS]:
    "Información de la solicitud actualizada",
  [requestAdmin.CLOSE_REQUEST_SUCCESS]:
    "Petición para cerrar solicitud enviada",
  [request.SEND_USER_NOTIFICATION_REQUEST_SUCCESS]:
    "Reenviada notificación al usuario",
  [requestAdmin.CANCEL_REQUEST_SUCCESS]: "Solicitud cancelada con éxito.",
};

export const failureMessages = {
  [request.SAVE_LEGAL_PERSON_ORDER_FAILURE]: "La solicitud no pudo ser creada.",
  [request.SAVE_NATURAL_PERSON_ORDER_FAILURE]:
    "La solicitud no pudo ser creada.",
  [profile.SAVE_PROFILE_EDITION_FAILURE]:
    "Los cambios no pudieron ser guardados.",
  [clients.DELETE_CONTACT_FAILURE]: "El contacto no pudo ser eliminado.",
  [clients.SAVE_NEW_CONTACT_FAILURE]: "El contacto no pudo ser creado.",
  [clients.SAVE_NEW_CLIENT_FAILURE]: "El cliente no pudo ser creado.",
  [agreements.SAVE_NEW_AGREEMENT_FAILURE]: "El acuerdo no pudo ser creado.",
  [agreements.DELETE_AGREEMENT_FAILURE]: "EL acuerdo no pudo ser eliminado",
  [users.SET_CHANGE_PASSWORD_FAILURE]: "Error al restablecer la contraseña.",
  [clients.EDIT_CONTACT_FAILURE]: "El cliente no pudo ser editado.",
  [clients.EDIT_CLIENT_PREFERENCES_FAILURE]: "El cliente no pudo ser editado.",
  [request.SAVE_REQUEST_STATUS_FAILURE]: "La solicitud no pudo ser cancelada.",
  [requestAdmin.APPROVE_REQUEST_FAILURE]:
    "Error al enviar solicitud de aprobación",
  [APPROVE_REQUEST_VALIDATION_FAILURE]:
    "Error al validar la solicitud de aprobación: Falta rellenar datos requeridos",
  [certificateAdmin.FETCH_CERTIFICATE_FAILURE]:
    "Error al descargar certificado digital",
  [documentsAdmin.SAVE_DOCUMENTS_C_FAILURE]: "Error al subir el documento",
  [requestAdmin.UPDATE_REQUEST_FAILURE]: "Error al actualizar la información",
  [requestAdmin.CLOSE_REQUEST_FAILURE]: "Error al cerrar solicitud",
  [request.SEND_USER_NOTIFICATION_REQUEST_FAILURE]:
    "Error al reenviar notificación al usuario",
  [request.FETCH_DOCUMENT_BY_ID_FAILURE]:
    "Fichero no encontrado. Puede que el fichero haya sido eliminado por antigüedad",
  [requestAdmin.CANCEL_REQUEST_FAILURE]: "La solicitud no pudo ser cancelada.",
};

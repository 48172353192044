import types from "../../actions/clientsActions/types";
import { FETCH_STATUS } from "../../../constants";

export const clientInvoice = (
  state = {
    data: [],
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error } = {} } = action;

  switch (action.type) {
    case types.FETCH_CLIENT_INVOICE_ID_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.FETCH_CLIENT_INVOICE_ID_SUCCESS:
      return {
        ...state,
        status: FETCH_STATUS.LOADED,
        data,
      };
    case types.FETCH_CLIENT_INVOICE_ID_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
    case types.CLEAR_INVOICE_DOC:
      return {
        data: [],
        status: FETCH_STATUS.NOT_LOADED,
        error: "",
      };
    default:
      return state;
  }
};

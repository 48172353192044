import React from "react";
import { Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import Main from "./routers/index";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

const history = createBrowserHistory();
ReactGA.initialize('UA-180549208-1');
ReactGA.pageview(window.location.pathname + window.location.search);

history.listen((location) => {
  ReactGA.pageview(window.location.pathname + window.location.search);
});

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Route>
          <Main />
        </Route>
      </Router>
    </Provider>
  );
}

export default App;

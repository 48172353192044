import { createActionTypes } from "../../../utils";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...createActionTypes("REQUEST_ADMIN", "APPROVE_REQUEST"),
  ...createActionTypes("REQUEST_ADMIN", "UPDATE_REQUEST"),
  ...createActionTypes("REQUEST_ADMIN", "CLOSE_REQUEST"),
  ...createActionTypes("REQUEST_ADMIN", "CANCEL_REQUEST"),
};

export const APPROVE_REQUEST_VALIDATION_FAILURE =
  "APPROVE_REQUEST_VALIDATION_FAILURE";

import React from "react";
import { Modal, Button } from "react-bootstrap";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  h2 {
    text-align: center;
    padding: 2%;
  }
`;

const StyledButtonContainer = styled.div`
  width: 100%;
`;

const StyledButton = styled(Button)`
  background-color: ${(props) =>
    props.confirm ? "var(--mainColor)" : "transparent"};
  color: ${(props) => (props.confirm ? "#fff" : "red")};
  border: ${(props) =>
    props.confirm ? "none" : "1px solid var(--alertColor)"};
  &:hover {
    background-color: ${(props) =>
      props.confirm ? "var(--mainColor)" : "var(--alertColor)"};
    border: ${(props) =>
      props.confirm ? "none" : "1px solid var(--alertColor)"};
  }
`;
function ModalContainer({
  show,
  message,
  onHide,
  onConfirm,
  hideButton,
  hiddeAllButtons,
}) {
  return (
    <StyledModal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal fade"
      backdrop="static"
    >
      <Modal.Body>
        <h2>{message}</h2>
      </Modal.Body>
      {hiddeAllButtons ? null : (
        <Modal.Footer>
          <StyledButtonContainer>
            <StyledButton block onClick={onConfirm} color="none" confirm>
              Confirmar
            </StyledButton>
            {hideButton ? null : (
              <StyledButton block onClick={onHide} cancel>
                Cancelar
              </StyledButton>
            )}
          </StyledButtonContainer>
        </Modal.Footer>
      )}
    </StyledModal>
  );
}

export default React.memo(ModalContainer);

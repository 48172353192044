import { LABELS, TYPEOPTIONS, REQUEST_STATUS } from "../constants";
import styles from "../view/requests/requestDetail/request-detail.module.css";
import moment from "moment";

export const createActionTypes = (key, baseAction) => ({
  [`${baseAction}`]: `${key}/${baseAction}`,
  [`${baseAction}_REQUEST`]: `${key}/${baseAction}_REQUEST`,
  [`${baseAction}_SUCCESS`]: `${key}/${baseAction}_SUCCESS`,
  [`${baseAction}_FAILURE`]: `${key}/${baseAction}_FAILURE`,
});

export function dniVerifier(value) {
  let letterList = "TRWAGMYFPDXBNJZSQVHLCKET";
  let numbersArray = value.split("");
  let onlyNumbers = numbersArray.filter((num) => num.match(/^\d+$/)).join("");
  let position = onlyNumbers % 23;

  let letter = letterList.substring(position, position + 1);
  let finalValue = onlyNumbers.concat(letter);

  return finalValue === value.toUpperCase() ? finalValue : false;
}

export const b64toFile = (b64Data, filename, contentType, clearFn) => {
  var sliceSize = 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);

    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var file = new File(byteArrays, filename, { type: contentType });
  let urlTest = window.URL.createObjectURL(file);

  //genero URL de descarga para ese archivo
  let a = global.document.createElement("a");
  a.href = urlTest;
  a.download = `${filename}`;
  a.click();
  clearFn();
};

export const formatterLanguageStatus = (status) => {
  if (status) {
    const newStatus =
      status[0].charAt(0).toUpperCase() + status.slice(1, status.length);
    switch (newStatus) {
      case "PROCESSING":
        return "Pendiente cliente";
      case "INCOMPLETE":
        return "Incompleta";
      case "REJECTED":
        return "Rechazada";
      case "CANCELLED":
        return "Cancelada";
      case "CANCELLLEDBYTIMEOUT":
        return "Cancelada";
      case "REVOKED":
        return "Revocada";
      case "COMPLETED":
        return "Completada";
      case "CLOSED":
        return "Completada";
      case "STARTED":
      case "SUCCESS":
        return "Iniciada";
      case "PENDING":
      case "APPROVED":
        return "Pendiente Cliente";
      case "EMITTED":
      case "PUBLISH":
        return "Aceptado cliente";
      default:
        return newStatus;
    }
  }
};

export const formatterLanguageSource = (source) => {
  switch (source) {
    case "cirbox_system":
      return "Sistema cirbox";
    case "user":
      return "Usuario";
    default:
      return source;
  }
};

// List viene de Lista de solicitudes
export const showTypeRequest = (typesRequests, list) => {
  let res = "";
  if (list) {
    if (typesRequests.length > 2) {
      res = `${TYPEOPTIONS[typesRequests[0]]}, ${
        TYPEOPTIONS[typesRequests[1]]
      }...`;
    } else {
      typesRequests.forEach((typeRequest, index) => {
        res += `${TYPEOPTIONS[typeRequest]}${
          index === typesRequests.length - 1 ? "" : ", "
        }`;
      });
    }
  } else {
    typesRequests.forEach((typeRequest, index) => {
      res += `${TYPEOPTIONS[typeRequest]}${
        index === typesRequests.length - 1 ? "" : ", "
      }`;
    });
  }
  return res;
};

export const requestStatusHandler = (status, type, certificates) => {
  let requestType = showTypeRequest(type);

  let request = {
    requestStatus: REQUEST_STATUS.iniciada,
    className: "",
    showDownloads: certificates && !!certificates.length,
    requestType: requestType,
  };

  switch (status) {
    case LABELS.request.requestDetail.status.revoked:
      request.requestStatus = REQUEST_STATUS.revocada;
      request.className = styles.deniedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.processing:
      request.requestStatus = REQUEST_STATUS.procesando;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.incomplete:
      request.requestStatus = REQUEST_STATUS.incompleta;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.started:
      request.requestStatus = REQUEST_STATUS.iniciada;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.success:
      request.requestStatus = REQUEST_STATUS.iniciada;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.pending:
      request.requestStatus = REQUEST_STATUS.pendiente;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.approved:
      request.requestStatus = REQUEST_STATUS.aprobada;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.emitted:
      request.requestStatus = REQUEST_STATUS.emitida;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.publish:
      request.requestStatus = REQUEST_STATUS.publicada;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.completed:
      request.requestStatus = REQUEST_STATUS.completada;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.closed:
      request.requestStatus = REQUEST_STATUS.cerrada;
      request.className = styles.approvedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;

    case LABELS.request.requestDetail.status.rejected:
      request.requestStatus = REQUEST_STATUS.rechazada;
      request.className = styles.deniedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.cancelled:
      request.requestStatus = REQUEST_STATUS.cancelada;
      request.className = styles.deniedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;
    case LABELS.request.requestDetail.status.cancelledByTimeOut:
      request.requestStatus = REQUEST_STATUS.cancelada;
      request.className = styles.deniedAnswer;
      request.showDownloads = certificates && !!certificates.length;
      break;

    default:
      return request;
  }
  return request;
};

export const showErrors = (errors) => {
  let newErrors;
  const personal_data = errors.detail.personal_data;
  if (personal_data !== undefined) {
    delete errors.detail.personal_data;
    newErrors = {
      ...personal_data,
      ...errors.detail,
    };
  } else {
    newErrors = {
      ...errors,
    };
  }
  return newErrors;
};

export const trimValues = (values) => {
  for (const propiedad in values) {
    let value = values[propiedad];
    if (typeof value === "string") {
      values[propiedad] = value.trim();
    }
  }
  return values;
};

//pasa de file a B64, reduce tamaño y vuelve a file
export const compress = (e) => {
  return new Promise((resolve, reject) => {
    const fileName = e.target.files[0].name;

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const elem = document.createElement("canvas");
        const ctx = elem.getContext("2d");
        let width = img.width * 0.5;
        let height = img.height * 0.5;
        elem.width = width;
        elem.height = height;
        ctx.scale(width / img.width, height / img.height);
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            const file = new File([blob], fileName, {
              type: "image/png",
              lastModified: Date.now(),
            });
            resolve(file);
          },
          "image/png",
          1
        );
      };
    };
  });
};

export const prepareFiles = (filesBuffer, fileValues, textValues) => {
  let valueToSend = {};
  let selectedFiles = {} 
  filesBuffer[0].forEach((key) => {
    //itero sobre el primer array de arrays
    //busco la keys en el objeto con los B64
    selectedFiles = {...selectedFiles, [key]: fileValues[key] }
    //en el caso de que haya texto junto al los files (ej primer envio)
    textValues
      ? (valueToSend = { ...textValues, ...selectedFiles })
      : (valueToSend = { ...valueToSend, ...selectedFiles });
  });
  filesBuffer.shift(); //borro la posicion cero (primer array del buffer)
  return valueToSend;
};

export const formatterDate = (date) => {
  return (date = moment(date).format("DD/MM/YYYY"));
};

export const searchTableColumns = [
  {
    id: "1",
    styles: { fontWeight: "bold" },
    name: LABELS.request.requestList.tableHeader.fecha.name,
    dataField: LABELS.request.requestList.tableHeader.fecha.dataField,
  },
  {
    id: "2",
    styles: { fontWeight: "bold" },
    name: LABELS.request.requestList.tableHeader.solicitud.name,
    dataField: LABELS.request.requestList.tableHeader.solicitud.dataField,
  },
  {
    id: "3",
    styles: { fontWeight: "bold" },
    name: LABELS.request.requestList.tableHeader.nombreCompleto.name,
    dataField: LABELS.request.requestList.tableHeader.nombreCompleto.dataField,
  },
  {
    id: "4",
    styles: { fontWeight: "bold" },
    name: LABELS.request.requestList.tableHeader.tipoDeSolicitud.name,
    dataField: LABELS.request.requestList.tableHeader.tipoDeSolicitud.dataField,
  },
  {
    id: "5",
    styles: { fontWeight: "bold" },
    name: LABELS.request.requestList.tableHeader.document.name,
    dataField: LABELS.request.requestList.tableHeader.document.dataField,
  },
  {
    id: "6",
    styles: { fontWeight: "bold" },
    name: LABELS.request.requestList.tableHeader.estado.name,
    dataField: LABELS.request.requestList.tableHeader.estado.dataField,
  },
];

export const extractPage = (nextPage, curr) => {
  if (nextPage) {
    const page = new URL(nextPage).searchParams;
    return Number(page.get("page")) || 1;
  }
  return Number(curr);
}

export const replaceOrAddItemBy = (prop, newItem, items) => {
  const indexCertificate = items.findIndex(item => item[prop] === newItem[prop]);
  if (indexCertificate !== -1) {
    const newItems = [...items];
    newItems.splice(indexCertificate, 1, newItem);
    return newItems;
  }
  return [...items, newItem];
}

import types from "../../actions/agreementsActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const agreementDocument = (
  state = {
    data: [],
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
    },
  action
) => {
  const { payload: { data, error } = {} } = action;
  switch (action.type) {
    case types.FETCH_AGREEMENT_DOC_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.FETCH_AGREEMENT_DOC_SUCCESS:
      return {
        ...state,
        data: data,
        status: FETCH_STATUS.LOADED,
      };
    case types.FETCH_AGREEMENT_DOC_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
    case types.CLEAR_AGREEMENT_DOC:
      return {
        data: {},
        status: FETCH_STATUS.NOT_LOADED,
      };
    default:
      return state;
  }
};

export default agreementDocument;

import { combineReducers } from "redux";
import { userReducer } from "./user/user-reducer";
import { newRequest } from "./newRequest/new-request-reducer";
import { requests } from "./requests/requests-reducer";
import { requestDetail } from "./requests/request-detail-reducer";
import { notification } from "./notification/notifications-reducer";
import { requestDocumentsDetail } from "./requests/request-document-detail-reducer";
import { requestCertificate } from "./requests/request-certificate-reducer";
import { requestDetailUrl } from "./requests/request-detail-url-reducer";
import { requestSearch } from "./requests/request-certificate-search-reducer";
import { profile } from "./profile/profile-reducer";
import { contactList } from "./clients/contact-list-reducer";
import { newContact } from "./clients/new-contact-reducer";
import { activities } from "./activities/activities-list-reducer";
import { activitiesSearch } from "./activities/activities-list-search-reducer";
import { suscriptions } from "./suscriptions/suscriptions-list-reducer";
import { clientList } from "./clients/client-list-reducer";
import { agreements } from "./agreements/agreements-list-reducer";
import { newAgreement } from "./agreements/new-agreement-reducer";
import { newClient } from "./clients/new-client-reducer";
import { clientDetail } from "./clients/client-detail-reducer";
import { associateList } from "./clients/associate-list-reducer";
import { editPreferences } from "./clients/edit-client-preferences-reducer";
import { clientPreferences } from "./clients/client-preferences-reducer";
import { clientSearch } from "./clients/client-search-reducer";
import { updateRequest } from "./newRequest/update-request-reducer";
import { clientInvoices } from "./clients/client-invoices-reducer";
import { updateAgreementDocument } from "./agreements/update-document-reducer";
import { agreementDocument } from "./agreements/agreement-document-reducer";
import { deleteAgreementDocument } from "./agreements/delete-agreement-reducer";
import { clientInvoice } from "./clients/client-invoice-id-reducer";
import { digitalCertificate } from "./digitalCertificate/certificate-reducer";
import { revokeDigitalCertificate } from "./digitalCertificate/revoke-certificate-reducer";
import { deleteDocumentC } from "./documentsC/delete-document-reducer";
import { saveDocumentC } from "./documentsC/document-reducer";
import { approveRequestAdmin } from "./requestAdmin/request-approve-status";
import { changeStatusRequest } from "./requests/request-status-update";
import { updateRequestRegister } from "./requestAdmin/request-update-register";
import { cancelRequestAdmin } from "./requestAdmin/request-cancel";


const allReducers = combineReducers({
  user: userReducer,
  newRequest,
  requests,
  requestDetail,
  notification,
  documentDetail: requestDocumentsDetail,
  certificate: requestCertificate,
  requestDetailUrl,
  requestSearch,
  profile,
  contactList,
  newContact,
  activities,
  activitiesSearch,
  newClient,
  newAgreement,
  agreements,
  suscriptions,
  clientList,
  clientDetail,
  associateList,
  editPreferences,
  clientPreferences,
  clientSearch,
  updateRequest,
  clientInvoices,
  updateAgreementDocument,
  agreementDocument,
  deleteAgreementDocument,
  clientInvoice,
  digitalCertificate,
  revokeDigitalCertificate,
  deleteDocumentC,
  saveDocumentC,
  approveRequestAdmin,
  deleteRequestAdmin: changeStatusRequest,
  updateRequestRegister,
  cancelRequestAdmin
});

const reducers = (state, action) => {
  return allReducers(state, action);
};

export default reducers;

import { createActionTypes } from "../../../utils";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...createActionTypes("REQUEST", "SAVE_LEGAL_PERSON_ORDER"),
  ...createActionTypes("REQUEST", "SAVE_NATURAL_PERSON_ORDER"),
  ...createActionTypes("REQUEST", "FETCH_REQUEST_LIST"),
  ...createActionTypes("REQUEST", "FETCH_REQUEST_BY_ID"),
  ...createActionTypes("REQUEST", "FETCH_DOCUMENT_BY_ID"),
  ...createActionTypes("REQUEST", "FETCH_CERTIFICATE_BY_ID"),
  ...createActionTypes("REQUEST", "FETCH_REQUEST_BY_SEARCH"),
  ...createActionTypes("REQUEST", "SAVE_REQUEST_STATUS"),
  ...createActionTypes("REQUEST", "UPDATE_NATURAL_PERSON_ORDER"),
  ...createActionTypes("REQUEST", "UPDATE_LEGAL_PERSON_ORDER"),
  ...createActionTypes("REQUEST", "SEND_USER_NOTIFICATION_REQUEST")
};

export const CLEAR_DOCUMENT_REQUEST_BY_ID = "CLEAR_DOCUMENT_REQUEST_BY_ID";
export const CLEAR_CERTIFICATE_REQUEST_BY_ID =
  "CLEAR_CERTIFICATE_REQUEST_BY_ID";
export const SET_REQUEST_DETAIL_URL_FROM_EMAIL =
  "SET_REQUEST_DETAIL_URL_FROM_EMAIL";
export const CLEAR_REQUEST_BY_SEARCH = "CLEAR_REQUEST_BY_SEARCH";
export const SET_REQUEST = "SET_REQUEST";
export const SET_REQUEST_CERTIFICATE = "SET_REQUEST_CERTIFICATE";

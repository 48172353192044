import types from "../../actions/userActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const userReducer = (
  state = {
    data: {},
    token: "",
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error } = {} } = action;

  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
 
      return {
        ...state,
        token: data,
        status: FETCH_STATUS.LOADED,
      };
    case types.LOGIN_USER_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error: error,
      };
    case types.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        data: data,
        status: FETCH_STATUS.LOADED,
      };
    case types.GET_NEW_TOKEN_SUCCESS:
      return {
        ...state,
        token: data,
        status: FETCH_STATUS.LOADED,
      };
    case types.GET_NEW_TOKEN_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error: error,
      };
      case types.GET_NEW_PASSWORD_SUCCESS:
        return {
          ...state,
          status: FETCH_STATUS.LOADED,
        };
      case types.GET_NEW_PASSWORD_FAILURE:
        return {
          ...state,
          status: FETCH_STATUS.FAILED,
          error: error,
        };
    default:
      return state;
  }
};

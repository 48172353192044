import types from "./types";
import { userService } from "../../../services/userService";

/*-------- LOGIN USER -------------- */

const loginRequest = () => {
  return {
    type: types.LOGIN_USER_REQUEST,
  };
};
const loginSuccess = (data) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload: data,
  };
};
const loginFailure = (error) => {
  return {
    type: types.LOGIN_USER_FAILURE,
    payload: error,
  };
};

export const login = (user) => (dispatch) => {
  dispatch(loginRequest());
  return userService
    .login(user.username, user.password)
    .then((response) => dispatch(loginSuccess(response)))
    .catch((error) => dispatch(loginFailure({ error })));
};

/*-------- LOGOUT USER -------------- */
const logoutRequest = () => {
  return {
    type: types.LOGOUT_USER_REQUEST,
  };
};
const logoutSuccess = (data) => {
  return {
    type: types.LOGOUT_USER_SUCCESS,
    payload: data,
  };
};
const logoutFailure = (error) => {
  return {
    type: types.LOGOUT_USER_FAILURE,
    payload: error,
  };
};

export const logout = () => (dispatch) => {
  dispatch(logoutRequest());
  return userService
    .logout()
    .then((response) => dispatch(logoutSuccess(response)))
    .catch((error) => dispatch(logoutFailure({ error })));
};

/*------------------ GET NEW TOKEN -------------------*/

const getNewTokenSuccess = (data) => {
  return {
    type: types.GET_NEW_TOKEN_SUCCESS,
    payload: data,
  };
};
const getNewTokenFailure = (error) => {
  return {
    type: types.GET_NEW_TOKEN_FAILURE,
    payload: error,
  };
};

export const getNewToken = () => (dispatch) => {
  return userService
    .getNewToken()
    .then((response) => dispatch(getNewTokenSuccess(response)))
    .catch((error) => dispatch(getNewTokenFailure("Error getting profile")));
};

/*---------------------SEND MAIL TO GET NEW PASSWORD--------------- */

const getNewPasswordSuccess = (data) => {
  return {
    type: types.GET_NEW_PASSWORD_SUCCESS,
    payload: data,
  };
};
const getNewPasswordFailure = (error) => {
  return {
    type: types.GET_NEW_PASSWORD_FAILURE,
    payload: error,
  };
};

export const requestNewPassword = (data) => (dispatch) => {
  return userService
    .getNewPassword(data)
    .then((response) => dispatch(getNewPasswordSuccess(response)))
    .catch((error) =>
      dispatch(getNewPasswordFailure("Error al enviar el email, reintente"))
    );
};

/*---------------------SET NEW PASSWORD--------------- */

const setNewPasswordsuccess = (data) => {
  return {
    type: types.SET_NEW_PASSWORD_SUCCESS,
    payload: data,
  };
};

const setNewPasswordFailure = (error) => {
  return {
    type: types.SET_NEW_PASSWORD_FAILURE,
    payload: error,
  };
};

export const setNewPassword = (data) => (dispatch) => {
  return userService
    .setNewPassword(data)
    .then((response) => dispatch(setNewPasswordsuccess(response)))
    .catch((error) =>
      dispatch(setNewPasswordFailure("Error al reestablecer contraseña"))
    );
};

/*---------------------SET NEW PASSWORD--------------- */

const setChangePasswordsuccess = (data) => {
  return {
    type: types.SET_CHANGE_PASSWORD_SUCCESS,
    payload: data,
  };
};

const setChangePasswordFailure = (error) => {
  return {
    type: types.SET_CHANGE_PASSWORD_FAILURE,
    payload: error,
  };
};

export const setChangePassword = (data) => (dispatch) => {
  return userService
    .setChangePassword(data)
    .then((response) => dispatch(setChangePasswordsuccess(response)))
    .catch((error) =>
      dispatch(setChangePasswordFailure("Error al reestablecer contraseña"))
    );
};

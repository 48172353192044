/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/userActions";

import ModalContainer from "../Modal";
import UserInfo from "./userInfo";
import { TITLE_DICTIONARY, REGEX } from "../../constants";

const Header = (props) => {
  let userName = "";
  let companyName = "";
  let companyLogo = "";

  let { natural_person, personal_data, company } = props;

  userName = natural_person
    ? `${natural_person.first_name} ${natural_person.last_name_1} `
    : personal_data
    ? `${personal_data.first_name} ${personal_data.last_name_1} `
    : null;

  companyName = company ? company.commercial_name : null;
  companyLogo = company ? company.logo : null;

  let {
    location: { pathname },
    currentRequest: { serial },
  } = props;
  const [showModal, setShowModal] = useState(false);

  const handleClick = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleBack = () => {
    props.history.goBack();
  };

  const handleConfirm = useCallback(() => {
    props.logout().then(() => {
      setShowModal(false);
      props.history.push("/login");
    });
  }, [showModal]);

  const headerHandler = () => {
    let pathArray = pathname
      .split("/")
      .filter((element) => !element.match(REGEX.URL_ID));
    let path = pathArray.join("/");

    let title = TITLE_DICTIONARY[path];
    let detail = !title ? TITLE_DICTIONARY["/requests/detail/:id"] : "";
    let isAdmin = pathname.includes("admin");

    return (
      <div className={`col-md-6 ${detail ? "request_list-title" : ""}`}>
        <h1 className={`title ${detail ? "request_list-title" : ""}`}>
          {detail ? (
            isAdmin ? (
              <Link to="" onClick={handleBack} className="icon-backicon">
                &#xe908;
              </Link>
            ) : (
              <Link to="" onClick={handleBack} className="icon-backicon">
                &#xe908;
              </Link>
            )
          ) : (
            ""
          )}
          {title ? title : serial ? `${detail} ${serial}` : detail}
        </h1>
      </div>
    );
  };

  return (
    <>
      {headerHandler()}
      <ModalContainer
        show={showModal}
        onHide={handleClick}
        onConfirm={handleConfirm}
        message={"¿Está seguro que desea salir?"}
      />

      <UserInfo
        companyLogo={companyLogo}
        companyName={companyName}
        userName={userName}
        handleClick={handleClick}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  let {
    profile: {
      data: { company, personal_data },
    },
    newRequest: { status: newRequestStatus },
    requestDetail: { data: currentRequest },
  } = state;
  return {
    company,
    newRequestStatus,
    currentRequest,
    personal_data,
  };
};
const mapDispatchToProps = {
  logout,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

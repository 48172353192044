/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserData } from "../store/actions/profileActions";
import { productsService } from "../services/productsService"
import Spinner from "../components/Spinner";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../components/dashboard";
import ToastNotification from "../components/ToastNotification";

const GeneralData = lazy(() => import("../view/generalData"));
const RequestList = lazy(() => import("../view/requests/"));
const NewRequest = lazy(() => import("../view/newRequest/"));
// const Invoices = lazy(() => import("../view/invoices/"));
const Clients = lazy(() => import("../view/adminViews/clients"));
const Extras = lazy(() => import("../view/adminViews/extras"));
const RequestListAdmin = lazy(() => import("../view/adminViews/requests/"));
const TermsAndConditions = lazy(() => import("../view/terms&Conditions"));

const Routes = ({ getUserData, status, is_staff, requestURLFromEmailLink }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserData()
    .then(()=> productsService.fetchProducts()
    .then(()=> setLoading(false)))
  }, [is_staff]);

  return (
    <>
      {status !== "LOADED" || loading ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          {is_staff ? (
            <Dashboard isAdmin={is_staff}>
              <Redirect from="/" to="/admin/requests/list/started" />
              <Switch>
                <PrivateRoute path="/admin/clients/" component={Clients} />
                <PrivateRoute
                  path="/admin/requests/list/"
                  component={RequestListAdmin}
                />
                <PrivateRoute
                  path="/admin/requests/detail/:id?"
                  component={RequestListAdmin}
                />
                {/* <PrivateRoute
                  path="/admin/invoices/:set?"
                  component={Invoices}
                /> */}
                <PrivateRoute path="/admin/extras/:set?" component={Extras} />
              </Switch>
              <ToastNotification />
            </Dashboard>
          ) : (
            <Dashboard>
              {/* Este redirect no me permite redirigir cuando me 
              logueo desde un link de mail hacia detalle de solicitud.
              se anula temporalmente si hay un requestURlFromEmailLink
              */}
              {!requestURLFromEmailLink && (
                <Redirect from="/" to="/requests/new/natural_person" />
              )}
              <Switch>
                <PrivateRoute path="/requests/new/" component={NewRequest} />
                <PrivateRoute path="/requests/list/" component={RequestList} />
                <PrivateRoute
                  path="/requests/detail/:id?"
                  component={RequestList}
                />
                <PrivateRoute
                  path="/general_data/:set?"
                  component={GeneralData}
                />
                <PrivateRoute path="/terms" component={TermsAndConditions} />
              </Switch>
              <ToastNotification />
            </Dashboard>
          )}
        </Suspense>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  let {
    profile: {
      data: { is_staff },
      status,
    },
    requestDetailUrl: { url: requestURLFromEmailLink },
  } = state;
  return {
    is_staff,
    status,
    requestURLFromEmailLink,
  };
};
const mapDispatchToProps = {
  getUserData,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));

import React from "react";
import styled from "styled-components";

const StyledSpinner = styled.div`
  position: absolute;
  z-index: 1000;
  top: ${(props) => (props.positionY ? props.positionY : "45%")};
  left: ${(props) => (props.positionX ? props.positionX : "45%")};
  display: grid;
  grid-template-rows: 1fr 1fr;
  div {
    justify-self: center;
    margin-bottom: 5%;
  }
  span {
    font-size: 1rem;
    z-index: 2000;
    display: contents;
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 1;
`;

const Spinner = ({ message, positionY, positionX, activeBackground }) => {
  return (
    <>
      {activeBackground ? (
        <StyledOverlay className="col-md-12 requestForm">
          <StyledSpinner
            positionY={positionY}
            positionX={positionX}
            className="text-center"
          >
            <div className="spinner-border" role="status"></div>
            <span className="sr-only">{message && message}</span>
          </StyledSpinner>
        </StyledOverlay>
      ) : (
        <StyledSpinner
          positionY={positionY}
          positionX={positionX}
          className="text-center"
        >
          <div className="spinner-border" role="status"></div>
          <span className="sr-only">{message && message}</span>
        </StyledSpinner>
      )}
    </>
  );
};
export default React.memo(Spinner);

import React from "react";
import { Link } from "react-router-dom";
import logo from '../../Pinpass_logo_provisional.webp';

const Topbar = (props) => {
  return (
    <div className="col-md-12">
      <span className="logoContainer">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <Link to="/requests/list/pending">
        {/* <Link to ="/terms"> */}
          
          <img src={logo} alt="logo"/>
        </Link>
      </span>
    </div>
  );
};

export default React.memo(Topbar);

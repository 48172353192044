import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const UserInfo = ({ companyLogo, companyName, userName, handleClick }) => {
  return (
    <div className="col-md-6 col-lg-6 Client-module_container">
      <div className="ClientModule col align-self-end">
        <div className="infoClient-container">
          <span className="UserName">{userName}</span>
          {companyName && <span className="CompanyName">{companyName}</span>}
        </div>
        {companyLogo && (
          <div className="LogoClientContainer">
            <img src={companyLogo} alt="" />
          </div>
        )}
        <div className="logout-container">
          <FontAwesomeIcon
            className="iconBack"
            icon={faSignOutAlt}
            onClick={handleClick}
          ></FontAwesomeIcon>{" "}
        </div>
      </div>
    </div>
  );
};
export default React.memo(UserInfo);

import types from "../../actions/clientsActions/types";
import { FETCH_STATUS } from "../../../constants";

export const clientPreferences = (
    state = {
        data: [],
        status: FETCH_STATUS.NOT_LOADED,
        error: "",
    },
    action
) => {
    const { payload: { data, error } = {} } = action;
    switch (action.type) {
        case types.FETCH_CLIENT_PREFERENCES_REQUEST:
            return {
                ...state,
                status: FETCH_STATUS.LOADING,
                error: null,
            };
        case types.FETCH_CLIENT_PREFERENCES_SUCCESS:
            return {
                ...state,
                data: data,
                status: FETCH_STATUS.LOADED,
            };
        case types.FETCH_CLIENT_PREFERENCES_FAILURE:
            return {
                ...state,
                status: FETCH_STATUS.FAILED,
                error,
            };

        default:
            return state;
    }
};
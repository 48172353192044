import axios from "axios";
import { PRODUCTS_PATH_ENDPOINT } from "../constants";

const fetchProducts = async () => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "GET",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };

  let response = await axios.get(PRODUCTS_PATH_ENDPOINT, requestOptions);
  if (response.status === 200)
    sessionStorage.setItem("products_available", JSON.stringify(response.data));

  return response.data;
};

export const productsService = {
  fetchProducts,
};

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const SENTRY_ENVIRONMENT = process.env.NODE_ENV;

Sentry.init({
  dsn: 'https://052d360217b64e959e1faba1034e4772@o374252.ingest.sentry.io/5271454',
  environment: SENTRY_ENVIRONMENT,
  maxBreadcrumbs: 50,
  debug: true,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

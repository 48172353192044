import React from "react";
import { withRouter } from "react-router-dom";
import Topbar from "../topbar";
import Menu from "../menu";
import Header from "../header";

const Dashboard = ({children, isAdmin}) => {
  return (
    <div className="container requestPage detail">
      <div className="row">
        <Topbar />
      </div>
      <div className="row container-content">
        <Menu isAdmin = {isAdmin}/>
        <section className="col-md-11">
          <div className="row">
            <Header />
          </div>
          <div className="row">{children}</div>
        </section>
      </div>
    </div>
  );
};

export default withRouter(Dashboard);

import axios from "axios";
import moment from "moment";

import {
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  RESET_PASSWORD_CONFIRM_ENDPOINT,
  CHANGE_PASSWORD_ENDPOINT,
} from "../constants";

const login = async (username, password) => {
  let response = await axios.post(LOGIN_ENDPOINT, {
    email: username,
    password: password,
  });
  if (response && response.status === 200) {
    let startTime = moment();
    response.data.startTime = startTime;
    response.data.hasTokenRefresh = false;
    sessionStorage.setItem("token_data", JSON.stringify(response.data));
    return response;
  } else throw Error("invalid user");
};

const logout = async () => {
  let { refresh_token, access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${access_token}`;
  let sessionData = { refresh_token };

  let requestOptions = {
    method: "POST",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  sessionStorage.removeItem("token_data");
  sessionStorage.removeItem("profile_data");
  sessionStorage.removeItem("profileSettings");

  let response = await axios.post(LOGOUT_ENDPOINT, sessionData, requestOptions);
  return response;
};

const getNewToken = async () => {
  let { refresh_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );
  let authorization = `${token_type} ${refresh_token}`;
  let sessionData = { refresh_token };

  let requestOptions = {
    method: "POST",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };

  let response = await axios.post(
    REFRESH_TOKEN_ENDPOINT,
    sessionData,
    requestOptions
  );
  if (response.status === 200) {
    let startTime = moment();
    response.data.startTime = startTime;
    response.data.hasTokenRefresh = true;
    sessionStorage.setItem("token_data", JSON.stringify(response.data));
  }

  return response;
};

const getNewPassword = async (email) => {
  let response = await axios.post(RESET_PASSWORD_ENDPOINT, {
    email,
  });
  if (response && response.status === 200) {
    return response;
  } else throw Error("invalid user");
};

const setNewPassword = async (data) => {
  const { token, uid } = data;
  let response = await axios.post(
    `${RESET_PASSWORD_CONFIRM_ENDPOINT}${uid}/${token}/`,
    data
  );
  if (response && response.status === 200) {
    return response;
  } else throw Error("invalid user");
};

const setChangePassword = async (data) => {
  let { access_token, token_type } = JSON.parse(
    sessionStorage.getItem("token_data")
  );

  let authorization = `${token_type} ${access_token}`;
  let requestOptions = {
    method: "PUT",
    mode: "no-cors",
    headers: {
      authorization,
    },
  };
  let response = await axios.put(
    CHANGE_PASSWORD_ENDPOINT,
    data,
    requestOptions
  );
  if (response && response.status === 200) {
    return response;
  } else throw Error("invalid user");
};

export const userService = {
  login,
  logout,
  getNewToken,
  getNewPassword,
  setNewPassword,
  setChangePassword,
};

import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
// import {useModal} from "../hooks";
import StyledModal from "../components/Modal";
import { getNewToken } from "../store/actions/userActions";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [showModal, setShowModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const modalHandler = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
      setRedirect(true);
      return true;
    }, 1000);
  };

  const checkAuth = () => {
    let tokenData = JSON.parse(sessionStorage.getItem("token_data"));
    let currentTime = moment();
    if (tokenData) {
      let {
        refresh_token,
        access_token,
        startTime,
        // hasTokenRefresh,
      } = tokenData;
      if (!access_token || !refresh_token) {
        return false;
      }
      let timeDiference = currentTime.diff(startTime, "seconds");
      if (timeDiference >= tokenData.access_expires_in) {
        return false;
        // return hasTokenRefresh ? false : rest.getNewToken(); //en caso de que usemos refresh_token
      }
      return true;
    } else return false;
  };

  return (
    <>
      <StyledModal
        hiddeAllButtons
        message={"Su sesion ha expirado"}
        show={showModal}
      ></StyledModal>
      <Route
        {...rest}
        render={(props) =>
          checkAuth(props) ? (
            <Component {...props} />
          ) : (
            <>
              {props.location.key ? modalHandler() : setRedirect(true)}
              {redirect && (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              )}
              )
            </>
          )
        }
      />
    </>
  );
};
const mapDispatchToProps = {
  getNewToken,
};

export default connect(null, mapDispatchToProps)(PrivateRoute);

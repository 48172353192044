import { SET_REQUEST_DETAIL_URL_FROM_EMAIL } from "../../actions/requestActions/types";

export const requestDetailUrl = (state = {}, { type, payload = {} }) => {
  switch (type) {
    case SET_REQUEST_DETAIL_URL_FROM_EMAIL:
      return { url: payload.url };
    default:
      return state;
  }
};

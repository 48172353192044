import types from "./types";
import { profileService } from "../../../services/profileService";

/*-------- GENERAL DATA PROFILE -------------- */

const getUserDataRequest = () => {
  return {
    type: types.GET_USER_DATA_REQUEST,
  };
};
const getUserDataSuccess = (data) => {
  return {
    type: types.GET_USER_DATA_SUCCESS,
    payload: data,
  };
};
const getUserDataFailure = (error) => {
  return {
    type: types.GET_USER_DATA_FAILURE,
    payload: error,
  };
};

export const getUserData = () => (dispatch) => {
  dispatch(getUserDataRequest());
  return profileService
    .getProfile()
    .then((response) => dispatch(getUserDataSuccess(response)))
    .catch((error) => dispatch(getUserDataFailure("Error getting profile")));
};

const saveProfileEditionRequest = () => {
  return {
    type: types.SAVE_PROFILE_EDITION_REQUEST,
  };
};

const saveProfileEditionSuccess = (data) => {
  return {
    type: types.SAVE_PROFILE_EDITION_SUCCESS,
    payload: data,
  };
};

const saveProfileEditionFailure = (error) => {
  return {
    type: types.SAVE_PROFILE_EDITION_FAILURE,
    payload: error,
  };
};

export const saveProfileEdition = (profile) => (dispatch) => {
  dispatch(saveProfileEditionRequest());
  return profileService
    .saveProfileEdition(profile)
    .then((response) => {
      return dispatch(saveProfileEditionSuccess(response));
    })
    .catch((error) => dispatch(saveProfileEditionFailure({ error })));
};

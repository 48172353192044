import styled from "styled-components";

const StyledInput = styled.input`
${(props) => (props.error ? "border: #f55757 solid 1px" : null)}
`;
const StyledLabel = styled.label`
${(props) => (props.error ? "border: #f55757 solid 1px" : null)}
`;


export{
    StyledInput,
    StyledLabel
}
import React from "react";
import Modal from "../../components/Modal";
import { useModal } from "../../hooks";
import Input from "../../components/Input";
import { LABELS } from "../../constants";
import logo from '../../Pinpass_logo_provisional.webp';

const ForgotPasswordForm = ({
  values,
  handleChange,
  handleSubmit,
  errors,
  history,
}) => {
  const path = "/login";
  let { showModal, hideModal, onConfirmRedirect } = useModal(
    false,
    values.status,
    path,
    history
  );
  return (
    <>
      <Modal
        message={LABELS.forgotPassword.modalMessage}
        show={showModal}
        onHide={hideModal}
        onConfirm={onConfirmRedirect}
        hideButton
      />
      <div className="container forgot">
        <div className="row justify-content-center align-items-center">
          <div className="loginContainer col-md-5">
            <span>
              <img src={logo} alt="logo" />
            </span>
            <div className="form-group col-md-10 offset-md-1">
              <p>{LABELS.forgotPassword.labels.ingreseCorreo}</p>
              <form onSubmit={handleSubmit}>
                <div className="emailfield">
                  <div className="form-group has-search">
                    <Input
                      iconIsActive={true}
                      iconType="&#xe90b;"
                      iconClass="icon-Black iconLogin"
                      type="email"
                      className="form-control"
                      placeholder={
                        LABELS.forgotPassword.labels.correoElectronico
                      }
                      id="person_email"
                      tabIndex="6"
                      name="person_email"
                      values={values.person_email}
                      errors={errors.person_email}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary col-md-12">
                  {LABELS.forgotPassword.labels.restablecerContraseña}
                </button>
              </form>
              <a href={"/login"}> {LABELS.forgotPassword.labels.volver}</a>
            </div>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="disclaimer col-md-6">
            {LABELS.forgotPassword.labels.derechos}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;

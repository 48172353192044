import types from "../../actions/requestActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const requests = (
  state = {
    data: {},
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error } = {}} = action;
  switch (action.type) {
    case types.FETCH_REQUEST_LIST_REQUEST:
        return {
          ...state,
          status: FETCH_STATUS.LOADING,
          error: null,
        };
      case types.FETCH_REQUEST_LIST_SUCCESS:
      
        return {
          ...state,
          data:data,
          status: FETCH_STATUS.LOADED,
        };
      case types.FETCH_REQUEST_LIST_FAILURE:
        return {
          ...state,
          status: FETCH_STATUS.FAILED,
          error,
        };
    default:
      return state;
  }
};

export default requests;
/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { login } from "../../store/actions/userActions/index";
import { setRequestDetailUrlFromEmail } from "../../store/actions/requestActions";
import { Link } from "react-router-dom";
import logo from '../../Pinpass_logo_provisional.webp';

const Login = (props) => {
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const history = useHistory();
  const requestDetailUrl =
    props.location.state && props.location.state.from.pathname;
  requestDetailUrl &&
    requestDetailUrl.includes("detail")?
    props.setRequestDetailUrlFromEmail(requestDetailUrl):
    props.setRequestDetailUrlFromEmail()

    
  //si NO viene desde un enlace externo, location.state va a ser null SIEMPRE
  //si viene de un link de email, tendra el dato "state.from.pathame:/XXX/XXX"
  //Guardo en store de redux

  const handleLogin = (e) => {
    e.preventDefault();
    props.login(user).then((response) => {
      let { type } = response;
      type === "USER/LOGIN_USER_SUCCESS"
        ? requestDetailUrl
          ? history.push(requestDetailUrl)
          : history.push("/")
        : null;
    });
  };

  const changeUserHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  let errorAlert = props.error ? (
    <div className="alert alert-danger fade show" role="alert">
      <strong>Usuario y/o contraseña incorrecto!</strong> revise la información
      ingresada.
    </div>
  ) : (
    ""
  );

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="loginContainer col-md-5">
          {/* <!-- Logo !--> */}
          <span>
            <img src={logo} alt="logo"/>
          </span>
          <div className="form-group col-md-10 offset-md-1">
            <br />
            {errorAlert}
            <form method={"post"} onSubmit={handleLogin}>
              <div className="emailfield">
                <div className="form-group has-search">
                  <span className="icon-Black iconLogin">&#xe90b;</span>
                  <input
                    type="email"
                    name={"username"}
                    className="form-control"
                    placeholder="Correo electrónico"
                    onChange={changeUserHandler}
                  />
                </div>
              </div>
              <div>
                <div className="form-group has-search">
                  <span className="icon-Black1 iconLogin">&#xe90a;</span>
                  <input
                    type="password"
                    name={"password"}
                    className="form-control"
                    placeholder="Contraseña"
                    onChange={changeUserHandler}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary col-md-12">
                Entrar
              </button>
            </form>
            <Link to={"/forgot_password"}>¿Olvido su contraseña?</Link>
          </div>
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="disclaimer col-md-6">
        © Derechos reservados Pinpass 2023
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  login,
  setRequestDetailUrlFromEmail,
};

const mapStateToProps = (state) => {
  let { error, status, user } = state.user;
  return {
    error,
    status,
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import types, {
  CLEAR_DOCUMENT_REQUEST_BY_ID,
} from "../../actions/requestActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const requestDocumentsDetail = (
  state = {
    data: {},
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error } = {} } = action;
  switch (action.type) {
    case types.FETCH_DOCUMENT_BY_ID_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.FETCH_DOCUMENT_BY_ID_SUCCESS:
      return {
        ...state,
        data: data,
        status: FETCH_STATUS.LOADED,
      };
    case types.FETCH_DOCUMENT_BY_ID_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
    case CLEAR_DOCUMENT_REQUEST_BY_ID:
      return {
        data: {},
        status: FETCH_STATUS.NOT_LOADED,
      };
    default:
      return state;
  }
};

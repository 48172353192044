import types from "../../actions/requestActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const newRequest = (
  state = {
    data: {},
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error } = {} } = action;
  switch (action.type) {
    case types.SAVE_LEGAL_PERSON_ORDER_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.SAVE_LEGAL_PERSON_ORDER_SUCCESS:
      return {
        ...state,
        status: FETCH_STATUS.LOADED,
        data
      };
    case types.SAVE_LEGAL_PERSON_ORDER_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
    case types.SAVE_NATURAL_PERSON_ORDER_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.SAVE_NATURAL_PERSON_ORDER_SUCCESS:
      return {
        ...state,
        data,
        status: FETCH_STATUS.LOADED,
      };
    case types.SAVE_NATURAL_PERSON_ORDER_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
    default:
      return state;
  }
};

export default newRequest;

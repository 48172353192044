import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import NewPassword from "../view/newPassword/index";
import Login from "../view/login";
import Routes from "./Routes";
import ForgotPassword from "../view/forgotPassword/index";
import PrivateRoute from "./PrivateRoute";

const Main = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route exact path="/forgot_password" component={ForgotPassword} />
      <Route
        exact
        path="/auth/password/reset/confirm/:uid/:token/"
        component={NewPassword}
      />
      <PrivateRoute path="/:set?" component={Routes} />
      <Redirect to="/" />
    </Switch>
  );
};

export default withRouter(Main);

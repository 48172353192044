import React from "react";
import MenuOptions from "./menuOptions";

const Menu = ({ isAdmin }) => {
  return (
    <aside className={isAdmin ? `menu-cirbox` : `menu`}>
      <MenuOptions isAdmin={isAdmin}></MenuOptions>
    </aside>
  );
};

export default React.memo(Menu);

import types, {
  SET_REQUEST,
  SET_REQUEST_CERTIFICATE,
} from "../../actions/requestActions/types";
import { FETCH_STATUS } from "../../../constants/index";
import { replaceOrAddItemBy } from "utils";

export const requestDetail = (
  state = {
    data: {},
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error } = {} } = action;
  switch (action.type) {
    case types.FETCH_REQUEST_BY_ID_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case SET_REQUEST_CERTIFICATE:
      if (!data.certificates?.length) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          certificates: replaceOrAddItemBy("request_type", data.certificates[0], state.data.certificates)
        },
        status: FETCH_STATUS.LOADED,
      };
    case SET_REQUEST:
    case types.FETCH_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        data: data,
        status: FETCH_STATUS.LOADED,
      };
    case types.FETCH_REQUEST_BY_ID_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };

    default:
      return state;
  }
};

export default requestDetail;

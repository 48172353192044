import React from "react";
import { withRouter, Link } from "react-router-dom";
import { useHistory } from "react-router";

import { StyledLi } from "./styledComponents";

const MenuOptions = (props) => {
  let {
    location: { pathname },
    isAdmin,
  } = props;

  const history = useHistory();
  const newRequestActive = { key: "new" };
  const requestActive = { key: "list" };
  const generalDataActive = { key: "general_data" };
  // const invoicesActive = { key: "invoices" };
  const extrasActive = { key: "extras" };
  const clientsActive = { key: "clients" };
  const requestAdminActive = { key: "requests" };

  const pathHandler = (viewName) => {
    return pathname.includes(viewName.key) ? true : false;
  };

  const refreshPage = (path) => {
    if (
      path === "/admin/requests/list/started" ||
      path === "/admin/requests/list/closed" ||
      path === "/admin/requests/list/cancelled"
      // path === "/requests/list/pending" ||
      // path === "/requests/list/closed" ||
      // path === "/requests/list/cancelled" ||
    )
      return history.go(0);
  };

  return (
    <>
      {isAdmin ? (
        <ul className="nav flex-column align-middle">
          <StyledLi
            active={pathHandler(requestAdminActive)}
            className="nav-item newRequestOption active"
            isAdmin
          >
            <Link
              className="nav-link"
              to={"/admin/requests/list/started"}
              onClick={() => refreshPage(pathname)}
            >
              <span className="icon-NewRequest">&#xe902;</span> Solicitudes
            </Link>
          </StyledLi>

          <StyledLi
            active={pathHandler(clientsActive)}
            className="nav-item active"
            isAdmin
          >
            <Link className="nav-link" to={"/admin/clients/list"}>
              <span className="icon-Request">&#xe906;</span> Clientes
            </Link>
          </StyledLi>

          {/* <StyledLi
            active={pathHandler(invoicesActive)}
            className="nav-item generalData active"
            isAdmin
          >
            <Link className="nav-link" to={"/admin/invoices/list"}>
              <span className="icon-userInfo">&#xe907;</span> Facturas
            </Link>
          </StyledLi> */}
          <StyledLi
            active={pathHandler(extrasActive)}
            className="nav-item generalData active"
            isAdmin
          >
            <Link className="nav-link" to={"/admin/extras/general_information"}>
              <span className="icon-userInfo">&#xe900;</span> Extras
            </Link>
          </StyledLi>
        </ul>
      ) : (
        <ul className="nav flex-column align-middle">
          <StyledLi
            active={pathHandler(newRequestActive)}
            className="nav-item newRequestOption active"
          >
            <Link className="nav-link" to={"/requests/new/natural_person"}>
              <span className="icon-NewRequest">&#xe901;</span> Nueva solicitud
            </Link>
          </StyledLi>

          <StyledLi
            active={pathHandler(requestActive)}
            className="nav-item active"
          >
            <Link
              className="nav-link"
              to={"/requests/list/pending"}
              // onClick={() => refreshPage(pathname)}
            >
              <span className="icon-Request">&#xe902;</span> Solicitudes
            </Link>
          </StyledLi>

          <StyledLi
            active={pathHandler(generalDataActive)}
            className="nav-item generalData active"
          >
            <Link className="nav-link" to={"/general_data/general_information"}>
              <span className="icon-userInfo">&#xe900;</span> Datos Generales
            </Link>
          </StyledLi>
        </ul>
      )}
    </>
  );
};

export default withRouter(MenuOptions);

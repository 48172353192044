import types from "../../actions/profileActions/types";
import { FETCH_STATUS } from "../../../constants/index";

export const profile = (
  state = {
    data: {},
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error } = {} } = action;

  switch (action.type) {
    case types.SAVE_PROFILE_EDITION_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.SAVE_PROFILE_EDITION_SUCCESS:
      return {
        ...state,
        data: data,
        status: FETCH_STATUS.LOADED,
      };
    case types.SAVE_PROFILE_EDITION_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
    case types.GET_USER_DATA_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
      };
    case types.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        data: data,
        status: FETCH_STATUS.LOADED,
        error: null,
      };
    case types.GET_USER_DATA_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };

    default:
      return state;
  }
};

import types, {
  APPROVE_REQUEST_VALIDATION_FAILURE,
} from "store/actions/requestAdminActions/types";
import { FETCH_STATUS } from "constants/index";

export const approveRequestAdmin = (
  state = {
    data: {},
    status: FETCH_STATUS.NOT_LOADED,
    error: "",
  },
  action
) => {
  const { payload: { data, error } = {} } = action;
  switch (action.type) {
    case types.APPROVE_REQUEST_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: null,
      };
    case types.APPROVE_REQUEST_SUCCESS:
      return {
        ...state,
        data: data,
        status: FETCH_STATUS.LOADED,
      };

    case APPROVE_REQUEST_VALIDATION_FAILURE:
    case types.APPROVE_REQUEST_FAILURE:
      return {
        ...state,
        status: FETCH_STATUS.FAILED,
        error,
      };
    default:
      return state;
  }
};
